import React, { useState, useRef, useEffect } from 'react';

import LogoDashes from '../Logo/LogoDashes';
import InputSquare from '../../components/InputSquare/InputSquare';

import PgsLogo from '../../assets/svg/pgs-logo.svg';
import ErrorArrow from '../../assets/img/mainView/sign-in-arrow.svg';

import './style.scss';

const SignIn = props => {
  const { className, errors } = props;

  const [userName, setUserName] = useState('');
  const [caretPosition, setCaretPosition] = useState(0);
  const [cursorIndex, setCursorIndex] = useState(0);
  const [userInputs, setUserInputs] = useState([]);
  const nickInput = useRef(null);

  useEffect(() => {
    if(errors && errors.length > 0) {
      const newInput = {
        error: errors[errors.length - 1],
        text: userName
      }
      setCursorIndex([...userInputs, newInput].length);
      setUserInputs([...userInputs, newInput]);
      setCaretPosition(0);
      setUserName('');
    }
  }, [errors]);

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      if (props.handleSubmit && userName) { 
        e.preventDefault();
        props.handleSubmit(userName);
      }
    } else if (e.keyCode == '38' && cursorIndex > 0 && userInputs[cursorIndex - 1]) {
      setUserName(userInputs[cursorIndex - 1].text);
      setTimeout(() => {
        setCursorIndex(cursorIndex - 1);
        setCaretPosition(userInputs[cursorIndex - 1].text.length);
        moveInputCaret(userInputs[cursorIndex - 1].text.length);
      }, 0);
    } else if (e.keyCode == '37' && caretPosition > 0) {
      setCaretPosition(caretPosition - 1);
    } else if (e.keyCode == '39' && caretPosition < userName.length) {
      setCaretPosition(caretPosition + 1);
    }
  }

  const handleInput = e => {
    const newUserName = e.target.value.split(' ').join('');
      
    if (cursorIndex !== userInputs.length) {
      setCursorIndex(userInputs.length);
    }
      
    if (newUserName !== userName) {
      setCaretPosition(nickInput.current.selectionStart > newUserName.length ? newUserName.length : nickInput.current.selectionStart);
    }

    setUserName(newUserName);
  };

  const moveInputCaret = (position) => {
    if (nickInput.current.createTextRange) {
      const range = nickInput.current.createTextRange();

      range.move('character', position);
      range.select();
      return true;
    }

    else {
      if (nickInput.current.selectionStart || nickInput.current.selectionStart === 0) {
        nickInput.current.focus();
        nickInput.current.setSelectionRange(position, position);
        return true;
      }
    }
  }

  const getUserNameLetters = () => userName.split('');

  const handleClick = () => {
    nickInput.current.focus();
  };

  return (
    <div
      className={`sign-in ${className ? className : ''}`}
      style={{ transform: `scale(${props.scale})` }}
      onClick={handleClick}
    >
      <div className="white-layer" />
      <div className="sign-in__logo">
        <LogoDashes />
        <img src={PgsLogo} type="" className="sign-in__logo-text" aria-label="pgs-logo" />
        <LogoDashes />
      </div>
      <form>
          {userInputs.map((input, index) => (
            <div className="sign-in__section" key={`user-input-${index}`}>
              <p className="sign-in__label">NICKNAME:</p>
              <p className="sign-in__nickname">{input.text}</p>
              <p key={`error-${index}`} className="sign-in__label">
                <img src={ErrorArrow} /> {input.error}
              </p>
            </div>
          ))}
        <label className="sign-in__section">
          <p className="sign-in__label">NICKNAME:</p>
          <input
            ref={nickInput}
            onKeyDown={handleKeyDown}
            onChange={handleInput}
            type="text"
            className="sign-in__nickname"
            autoFocus={true}
            value={userName}
            maxLength={7}
          />
          <div className="sign-in__input-wrapper">
            {
              getUserNameLetters().map((letter, index) => (
                <>
                  {
                    index === caretPosition &&
                    <InputSquare key={"input-square-" + index} />
                  }
                  <span key={"letter-" + index}>{letter}</span>
                </>
              ))
            }
            {
              (
                caretPosition === getUserNameLetters().length &&
                <InputSquare />
              )
            }
          </div>
        </label>
      </form>
      <div className="moving-layer"></div>
    </div>
  );
};

export default SignIn;
