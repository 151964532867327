import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

import GamePad from './GamePad';
import { socketUrl } from '../../helpers/constants';
import { getParam, replaceParamValue } from '../../helpers/router';

const events = require('../../helpers/events.js');

const {
  REMOVE_PAD_ARROW,
  ADD_PAD_ARROW,
  NEW_PAD_MATCHED
} = events;

const Controller = () => {
  const [orientation, setOrientation] = useState(null);
  const [showArrow, setShowArrow] = useState(false);
  const [socket, setSocket] = useState(null);
  const [nickname, setNickname] = useState('');
  const [randomHash, setRandomHash] = useState('');
  const [padId, setPadId] = useState(null);

  const initSocket = () => {
    const socket = io(socketUrl);

    socket.on(NEW_PAD_MATCHED, (padIndex, incomingHash) => {
      if(getParam(window.location.href, 'randomHash') === incomingHash) {
        setPadId(padIndex);
        replaceParamValue(window.location.href, 'padId', padIndex);
      }
    });

    socket.on(REMOVE_PAD_ARROW, () => {
      setShowArrow(false);
    });

    socket.on(ADD_PAD_ARROW, () => {
      setShowArrow(true);
    });

    setSocket(socket);
  };

  useEffect(() => {
    initSocket();
  }, []);

  useEffect(() => {
    setOrientation(window.orientation);

    window.addEventListener(
      'orientationchange',
      function() {
        setOrientation(window.orientation);
      },
      false,
    );

    if (window.location.pathname.includes('/controller')) {
      let nickname = getParam(window.location.href, 'nickname');
      let padId = getParam(window.location.href, 'padId');
      let randomHash = getParam(window.location.href, 'randomHash');

      if(nickname.length > 0 && padId.length > 0 && randomHash.length > 0) {
        setNickname(decodeURI(nickname));
        setPadId(padId);
        setRandomHash(randomHash);
      }
    }
  }, [orientation]);

  const move = direction => {
    this.setState({ moveBlock: direction });
    setTimeout(() => {
      this.setState({ moveBlock: '' });
    }, 0);
  };

  const rotate = direction => {
    this.setState({ rotateBlock: direction });
    setTimeout(() => {
      this.setState({ rotateBlock: '' });
    }, 0);
  };

  const triggerFasterFall = () => {
    this.setState({ fallFaster: true });
    setTimeout(() => {
      this.setState({ fallFaster: false });
    }, 0);
  };

  return (
    <>
      <GamePad
        showArrow={showArrow}
        padId={padId}
        randomHash={randomHash}
        triggerFasterFall={triggerFasterFall}
        move={move}
        rotate={rotate}
        socket={socket}
        nickname={nickname}
        verticalOrientation={!orientation}
      />
    </>
  );
};

export default Controller;
