import React from 'react';

import selectBox from '../../assets/svg/select-box.svg';
import selectBoxInvert from '../../assets/svg/select-box-invert.svg';
import selectBoxWhite from '../../assets/svg/select-box-white.svg';

const BuildingSelBox = ({ isBlinking }) => {
  return (
    <>
      <img
        aria-label="select box"
        className="select-box select-box-invert"
        src={selectBoxInvert}
        alt="select box"
        type=""
      />
      <img
        aria-label="select box"
        className={`select-box ${isBlinking ? 'select-box--white' : ''}`}
        src={selectBoxWhite}
        alt="select box"
        type=""
      />
      <img alt="select box" className="select-box" src={selectBox} type="" />
    </>
  );
};

export default BuildingSelBox;
