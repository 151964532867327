import React, { useState } from 'react';

function MainButton(props) {
  const [classes, setClasses] = useState('');

  const handleClick = () => {
    props.onClick();
    toggleTransformClass();
  };

  const toggleTransformClass = () => {
    setClasses('btn--transform');

    setTimeout(() => {
      setClasses('');
    }, 300);
  };

  return (
    <button aria-label="Submit" onClick={handleClick} className={`btn btn--rect ${props.className ? props.className : ''} ${classes}`}>
      <span className="btn--rect-text">{props.text}</span>
      <svg width="190px" height="46px" viewBox="0 0 190 46" version="1.1">
        <title>{props.text}</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="iPhone-XS-Copy-40" transform="translate(-90.000000, -715.000000)">
            <g id="select" transform="translate(90.000000, 715.000000)">
              <path
                d="M0,46 L0,35.6366708 L5.60179325,35.6366708 L5.60179325,18.555468 L11.4686901,18.555468 C11.4686901,10.185156 11.4686901,6 11.4686901,6 C11.4686901,6 70.9791267,6 190,6 L190,16.5912828 L184.745301,16.5912828 L184.745301,35.6366708 L179.013234,35.6366708 L179.013234,46 L0,46 Z"
                id="Path-27"
                fill="#8956BB"
              ></path>
              <path
                d="M0,40 L0,29.6366708 L5.60179325,29.6366708 L5.60179325,12.555468 L11.4686901,12.555468 C11.4686901,4.18515599 11.4686901,0 11.4686901,0 C11.4686901,2.7872437e-30 70.9791267,2.7872437e-30 190,0 L190,10.5912828 L184.745301,10.5912828 L184.745301,29.6366708 L179.013234,29.6366708 L179.013234,40 L0,40 Z"
                id="Path-27"
                fill="#BE0A8D"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </button>
  );
}
export default MainButton;
