import React from 'react';

import constantValues from '../../helpers/constants';
import './style.scss';
import star from "../../assets/svg/star.svg";

class Sky extends React.Component {
  starBlinkingTimeout = 2000;
  shootingStarBlinkingTimeout = 5000;
  maxStarYPosition = 100;
  starDirections = {
    fromLeft: 'from-left',
    fromRight: 'from-right',
  };
  newShootingStarTimeout = null;
  hideShootingStarTimeout = null;
  newStarBlinkingTimeout = null;
  hideStarBlinkingTimeout = null;
  state = {
    isStar: false,
    isShootingStar: false,
    shootingStar: {
      direction: this.starDirections[0],
      x: 0,
      y: 0,
    },
    star: {
      x: 0,
      y: 0,
    },
  };

  componentDidMount() {
    this.startShootingStars();
    this.startStarsBlinking();
  }

  componentWillUnmount() {
    clearTimeout(this.newShootingStarTimeout);
    clearTimeout(this.hideShootingStarTimeout);
    clearTimeout(this.newStarBlinkingTimeout);
    clearTimeout(this.hideStarBlinkingTimeout);
  }

  getRandomDirection(starX) {
    const starDirection =
      starX > constantValues.screenWidth / 2
        ? this.starDirections.fromRight
        : this.starDirections.fromLeft;

    return starDirection;
  }

  animateStarBlinking = () => {
    const starX = Math.round(Math.random() * constantValues.screenWidth);
    const starY = Math.round(Math.random() * (constantValues.screenHeight - this.maxStarYPosition));

    this.setState({
      star: {
        x: starX,
        y: starY,
      },
      isStar: true,
    });
    this.hideStarBlinkingTimeout = setTimeout(() => {
      this.setState({ isStar: false });
      this.startStarsBlinking();
    }, this.starBlinkingTimeout);
  };

  animateShootingStar() {
    const starX = Math.round(Math.random() * constantValues.screenWidth);

    this.setState({
      shootingStar: {
        direction: this.getRandomDirection(starX),
        x: starX,
        y: 0,
      },
      isShootingStar: true,
    });
    this.hideShootingStarTimeout = setTimeout(() => {
      this.setState({ isShootingStar: false });
      this.startShootingStars();
    }, this.shootingStarBlinkingTimeout);
  }

  startShootingStars = () => {
    this.newShootingStarTimeout = setTimeout(
      () => this.animateShootingStar(),
      Math.random() * 8000,
    );
  };

  startStarsBlinking = () => {
    this.newStarBlinkingTimeout = setTimeout(
      () => this.animateStarBlinking(),
      Math.random() * 8000,
    );
  };

  render() {
    return (
      <>
        {this.state.isShootingStar && (
          <span
            style={{
              top: String(this.state.shootingStar.y) + 'px',
              left: String(this.state.shootingStar.x) + 'px',
            }}
            className={`shooting-star shooting-star--${this.state.shootingStar.direction}`}
          />
        )}
        {this.state.isStar && (
          <img
            style={{
              top: String(this.state.star.y) + 'px',
              left: String(this.state.star.x) + 'px',
            }}
            className="blinking-star"
            src={star}
            alt="star"
          ></img>
        )}
        <div className="sky-background">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="336px"
            height="406px"
            version="1.1"
            viewBox="0 0 336 406"
          >
            {/* Generator: Sketch 54.1 (76490) - https://sketchapp.com---------------------------*/}
            <title>Group 2</title>
            <style type="text/css" />
            <script type="text/javascript" />
            <desc>Created with Sketch.</desc>
            <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
              <g id="start-game-copy" fill="#5454BA" transform="translate(-16.000000, -15.000000)">
                <g id="Group-2" transform="translate(16.000000, 15.000000)">
                  <g id="Group" transform="translate(0.000000, 401.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(32.000000, 319.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(205.000000, 389.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(298.000000, 372.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(158.000000, 302.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(231.000000, 319.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(331.000000, 289.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>

                  <g id="Group" transform="translate(61.000000, 268.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />

                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(94.000000, 215.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(11.000000, 209.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(91.000000, 189.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(34.000000, 169.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(54.000000, 147.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(34.000000, 109.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(74.000000, 90.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(124.000000, 109.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(74.000000, 69.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(154.000000, 92.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(171.000000, 155.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(234.000000, 222.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(306.000000, 182.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(274.000000, 122.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(204.000000, 72.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(306.000000, 41.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(265.000000, 0.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(184.000000, 12.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(44.000000, 2.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                  <g id="Group" transform="translate(164.000000, 242.000000)">
                    <rect className="star__inside" width="2" height="2" x="1" y="1" />
                    <rect
                      className="star__border"
                      width="4"
                      height="4"
                      x="0"
                      y="0"
                      opacity="0.202776228"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </>
    );
  }
}

export default Sky;
