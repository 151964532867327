import React from "react";

import star from "../../assets/svg/star.svg";
import blockRage from "../../assets/svg/block-rage.svg";

const Logo = () => {
  return (
    <div className="logo-container">
      <img src={star} aria-label="star" className="logo-star blinking-star"></img>
      <img src={blockRage} aria-label="logo" className="logo"></img>
      <div className="logo-shadow"></div>
    </div>
  );
};

export default Logo;
