export const getParam = (url, paramName) => {
  let paramValue = url.split(`${paramName}=`);
  
  paramValue = paramValue.length > 1 ? paramValue[1] : '';
  paramValue = paramValue.split('&');
  paramValue = paramValue[0];

  return paramValue;
}

export const replaceParamValue = (url, paramName, newValue) => {
  let urlPieces = url.split(`${paramName}=`);
  let newUrl = url;
  
  if(urlPieces.length > 1) {
    let remainingValues = urlPieces[1].split('&');

    remainingValues = remainingValues.slice(1);

    if(remainingValues && remainingValues.length > 0) {
      remainingValues.join('&');
      newUrl = `${urlPieces[0]}${paramName}=${newValue}&${remainingValues}`;
    } else {
      newUrl = `${urlPieces[0]}${paramName}=${newValue}`;
    }

    window.history.pushState({}, null, newUrl);
  }
}