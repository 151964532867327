import React from 'react';

const PadSliderSel = props => {
  const { data } = props.pad;

  return (
    <div className={"carousel-item pad-carousel-item " + props.classes}>
      <img alt="pad" src={data} className="pad-carousel-item__pad" />
    </div>
  );
};

export default PadSliderSel;
