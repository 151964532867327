import React from 'react';

import black2 from '../../assets/svg/black2.svg';
import black6 from '../../assets/svg/black6.svg';
import lamp from '../../assets/svg/lamp.svg';
import black4 from '../../assets/svg/black4.svg';
import black5 from '../../assets/svg/black5.svg';
import ufoSign from '../../assets/svg/ufo-sign.svg';

import { Parallax } from '..';
import { AppConsumer } from '../../AppContext';

const FirstPlanObjects = () => {
  return (
    <AppConsumer>
      {props => (
        <>
          <Parallax
            width={props.width}
            destination=""
            currentStep={props.currentStep}
            className="shrub1"
            position="2"
          >
            <img alt="shrub" src={black2} />
          </Parallax>
          <Parallax
            width={props.width}
            destination=""
            currentStep={props.currentStep}
            className="shrub2a"
            position="2"
          >
            <img alt="shrub" src={black2} />
          </Parallax>
          <Parallax
            width={props.width}
            destination=""
            currentStep={props.currentStep}
            className="lamppost"
            position="2"
          >
            <img alt="lamppost" src={lamp} />
          </Parallax>
          <Parallax
            width={props.width}
            currentStep={props.currentStep}
            className="lamppost2"
            position="2"
          >
            <img alt="ufo-sign" src={ufoSign} />
          </Parallax>
          <Parallax
            width={props.width}
            currentStep={props.currentStep}
            position="2"
            className="shrub6"
          >
            <img className="big-shurb" aria-label="shrub" src={black6} />
          </Parallax>
          <Parallax
            width={props.width}
            currentStep={props.currentStep}
            position="2"
            className="black4 black4--top"
          >
            <img alt="shrub" src={black4} />
          </Parallax>
          <Parallax
            width={props.width}
            currentStep={props.currentStep}
            position="2"
            className="black4 black4--bottom"
          >
            <img alt="shrub" src={black5} />
          </Parallax>
          <Parallax
            width={props.width}
            currentStep={props.currentStep}
            position="2"
            className="shrub6 shrub7"
          >
            <img className="big-shurb" aria-label="shrub" src={black6} />
          </Parallax>
        </>
      )}
    </AppConsumer>
  );
};

export default FirstPlanObjects;
