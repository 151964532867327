import React from 'react';

const shadeColor = (color, percent) => {
    if(!color) {
        return '';
    }

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  

    var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}

function BlockCell(props) {
    const { fillColor } = props;
    const darkenFillColor = shadeColor(fillColor, -20);

    return (
        <svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1">
            <title>block-red</title>
            <desc>Created with Sketch.</desc>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="iPhone-XS-Copy-60" transform="translate(-223.000000, -449.000000)">
                    <g id="Group-23" transform="translate(56.000000, 415.000000)">
                        <g transform="translate(167.000000, 34.000000)">
                            <rect fill={darkenFillColor} x="4.46153846" y="3.34615385" width="20.0769231" height="25.6538462"></rect>
                            <rect fill={darkenFillColor} x="0" y="7.80769231" width="29" height="16.7307692"></rect>
                            <rect fill={fillColor} x="4.46153846" y="0" width="20.0769231" height="24.5384615"></rect>
                            <rect fill={fillColor} x="0" y="4.46153846" width="29" height="15.6153846"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
  }
  
  export default BlockCell;