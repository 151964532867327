import React from 'react';

import ufo from '../../assets/svg/ufo.svg';
import cow from '../../assets/svg/cow.svg';

const UfoCowAbduction = () => {
  return (
    <div className={`ufo-cow-abduction animate`}>
      <img alt="ufo" className="ufo" src={ufo} />
      <img alt="cow" className="cow" src={cow} />
      <div className="ufo-light__container">
        <span className="ufo-light"></span>
        <span className="ufo-light-disc"></span>
      </div>
    </div>
  );
};

export default UfoCowAbduction;
