import React from 'react';

import cloud from '../../assets/svg/say-white.svg';
import clock from '../../assets/svg/clock2.svg';
import userMatchingLines from '../../assets/svg/user-matching-lines.svg';

import { avatars, avatarNames } from '../../helpers/mainData';
import { AppConsumer } from '../../AppContext';
import GameScores from '../GameBox/GameScores';
import MainButton from '../MainButton/MainButton';
import { getSuccessMessage } from '../../helpers/randomMessage';

function GameEndScreen() {
  return (
    <AppConsumer>
      {props => {
        const {
          chosenAvatarIndex,
          enemy, enemyScores,
          tempEnemyScores, scores
        } = props;
        const enemyAvatarId = enemy ? avatarNames.indexOf(enemy.avatar) : -1;
        const message = enemy
          ? enemyScores > scores
            ? `${getSuccessMessage(enemy.avatarIndex)}`
            : `${getSuccessMessage(chosenAvatarIndex)}`
          : '';

        return (
          <div className="user-matching game-over">
            <div className="user-matching__container">
              {enemyScores >= 0 && enemyScores !== scores && (
                <div
                  className={`game-over__message ${
                    enemyScores > scores ? 'game-over__message--bottom' : ''
                  }`}
                >
                  <img
                    className="enemy-response__message-bcg"
                    src={cloud}
                    type=""
                    aria-label="text cloud"
                  ></img>
                  <div className="enemy-response__message">{message}</div>
                </div>
              )}
              <div className="user-matching__main-content">
                <div className="user-matching__section user-matching__user user-matching__user--top">
                  <img
                    className="user-matching__user-avatar"
                    src={chosenAvatarIndex >= 0 ? avatars[chosenAvatarIndex] : null}
                    type=""
                    aria-label="my avatar"
                  ></img>
                  <div className="game-over__scores">
                    <GameScores scores={scores} />
                  </div>
                </div>
                {enemyScores >= 0 && (
                  <div className="game-over__msg">
                    {enemyScores > scores && <>You've lost</>}
                    {enemyScores < scores && <>You win</>}
                    {enemyScores === scores && <>Tie</>}
                  </div>
                )}
                {enemyScores < 0 && (
                  <>
                    <p className="game-over__msg game-over__msg--top">
                      Player: {enemy.nickname}
                      <br />
                      Is still playing!
                    </p>
                    <img className="game-over__msg game-over__msg--clock" src={clock}></img>
                    <p className="game-over__msg game-over__msg--bottom">you can wait or:</p>
                  </>
                )}
                <div className="user-matching__section user-matching__user user-matching__user--bottom">
                  {enemy && (
                    <>
                      <img
                        className="user-matching__user-avatar"
                        src={enemyAvatarId >= 0 ? avatars[enemyAvatarId] : null}
                        type=""
                        aria-label="my avatar"
                      ></img>
                      {(enemyScores >= 0 || tempEnemyScores >= 0) && (
                        <div className="game-over__scores">
                          <GameScores scores={enemyScores} />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <img
                className="user-matching__lines"
                src={userMatchingLines}
                type=""
                aria-label="user-matching"
              ></img>
            </div>
            <div className="game-over__button">
              <MainButton text={'main menu'} onClick={props.handleGamePlay} />
            </div>
          </div>
        );
      }}
    </AppConsumer>
  );
}

export default GameEndScreen;
