import React from 'react';

import './style.scss';

const Loader = props => {
  return (
    <div className="main-loader" style={{ width: props.width, transform: `scale(${props.scale})` }}>
      <div className="white-layer" />
      <div className="belt-1">
        <div className="belt-element" />
        <div className="belt-element" />
        <div className="belt-element" />
        <div className="belt-element" />
        <div className="belt-element" />
      </div>
      <div className="belt-2">
        <div className="belt-element" />
        <div className="belt-element" />
        <div className="belt-element" />
        <div className="belt-element" />
        <div className="belt-element" />
      </div>
      <p>V_Init: allocate</p>
      <p>Z_Init: Init WADfiles</p>
      <p>adding ./doomi.wad</p>
      <br />
      <p className="text--stretch text--pre">InitTextures … … … … … … ok</p>
      <p className="text--stretch text--pre">EXTextures … … … … … … ok</p>
    </div>
  );
};

export default Loader;
