const equal2d = (arr1, arr2, firstCheck = true) => {
    let isEqal = true;

    isEqal = arr1.every((element, index) => equal(element, arr2[index]));

    if(isEqal && firstCheck) {
        isEqal = equal2d(arr2, arr1, false);
    }

    return isEqal;
} 

const equal = (arr1, arr2, firstCheck = true) => {
    let isEqal = true;

    if(!arr1 || !arr2) {
        return false;
    }

    isEqal = arr1.every((element, index) => element === arr2[index]);

    if(isEqal && firstCheck) {
        isEqal = equal(arr2, arr1, false);
    }

    return isEqal;
} 

export { equal, equal2d };