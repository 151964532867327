import deadpool from '../assets/svg/carousel/deadpool.svg';
import ghost from '../assets/svg/carousel/ghost.svg';
import batman from '../assets/svg/carousel/batman.svg';
import viking from '../assets/svg/carousel/viking.svg';
import cyklop from '../assets/svg/carousel/cyklop.svg';
import ironman from '../assets/svg/carousel/ironman.svg';
import oldguy from '../assets/svg/carousel/oldguy.svg';
import rabbit from '../assets/svg/carousel/rabbit.svg';
import skull from '../assets/svg/carousel/skull.svg';
import starwars from '../assets/svg/carousel/starwars.svg';

import pad1 from '../assets/svg/carousel/pad1.svg';
import pad2 from '../assets/svg/carousel/pad2.svg';
import pad3 from '../assets/svg/carousel/pad3.svg';
import pad4 from '../assets/svg/carousel/pad4.svg';

const avatars = [batman, deadpool, ghost, cyklop, ironman, oldguy, rabbit, skull, starwars, viking];
const avatarNames = ['batman', 'deadpool', 'ghost', 'cyklop', 'ironman', 'oldguy', 'rabbit', 'skull', 'starwars', 'viking'];
const padNames = ['pad1', 'pad3', 'pad2', 'pad4', 'padFire'];

const pad1ButtonPositions = {
  arrowButtons: {
    w: '20',
    h: '20',
  },
  top: {
    top: '22.5',
    left: '39',
    translateY: '-100%',
    translateX: '0'
  },
  bottom: {
    top: '53.5',
    left: '39'
  },
  right: {
    top: '38.5',
    left: '56'
  },
  left: {
    top: '38.5',
    left: '22'
  },
  rotateButtons: {
    w: '23',
    h: '23',
  },
  rotateRight: {
    top: '37',
    right: '34'
  }
};

const pad2ButtonPositions = {
  arrowButtons: {
    w: '22',
    h: '22',
  },
  top: {
    top: '22.5',
    left: '44'
  },
  bottom: {
    top: '53.5',
    left: '44'
  },
  right: {
    top: '37.5',
    left: '60'
  },
  left: {
    top: '37.5',
    left: '26'
  },
  rotateButtons: {
    w: '23',
    h: '23',
  },
  rotateRight: {
    top: '30',
    right: '18'
  },
  rotateLeft: {
    top: '48',
    right: '50'
  }
};

const pad3ButtonPositions = {
  arrowButtons: {
    w: '20',
    h: '20',
  },
  top: {
    top: '20.5',
    left: '47.5'
  },
  bottom: {
    top: '58.5',
    left: '47.5'
  },
  right: {
    top: '40',
    left: '64'
  },
  left: {
    top: '40',
    left: '26'
  },
  rotateButtons: {
    w: '23',
    h: '23',
  },
  rotateRight: {
    top: '38',
    right: '34'
  }
};

const pad4ButtonPositions = {
  arrowButtons: {
    w: '20',
    h: '20',
  },
  top: {
    top: '20.5',
    left: '37'
  },
  bottom: {
    top: '59.5',
    left: '37'
  },
  right: {
    top: '40.5',
    left: '58'
  },
  left: {
    top: '40.5',
    left: '18'
  },
  rotateButtons: {
    w: '24',
    h: '24',
  },
  rotateRight: {
    top: '39',
    right: '19'
  },
  rotateLeft: {
    top: '39',
    right: '54'
  }
};

const pads = [
  {
    data: pad1,
    name: 'M1',
    uiVal: '90',
    uxVal: '40',
    buttonsPositions: pad1ButtonPositions,
  },
  {
    data: pad2,
    name: 'M2',
    uiVal: '40',
    uxVal: '90',
    buttonsPositions: pad2ButtonPositions,
  },
  {
    data: pad3,
    name: 'M3',
    uiVal: '90',
    uxVal: '40',
    buttonsPositions: pad3ButtonPositions,
  },
  {
    data: pad4,
    name: 'M4',
    uiVal: '40',
    uxVal: '90',
    buttonsPositions: pad4ButtonPositions,
  },
];

export { avatars, avatarNames, pads, padNames };
