import React from 'react';

import Logo from '../Logo/Logo';
import constants from '../../helpers/constants';
import steps from '../../helpers/steps';
import menuElementsSounds from '../../assets/sounds/menu-przyciski.wav';
import Beta from '../../assets/img/mainView/Beta.svg';

import './style.scss';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

const events = require('../../helpers/events.js');
const {
  MOVE_DOWN,
  MOVE_TOP
} = events;
const { viewSteps } = constants;
const {
  START_VIEW
} = steps;

class MenuElements extends React.Component {
  state = {
    selecting: false,
    deactivating: false,
    active: -1,
    currentStep: 0,
    singleViewWidth: 150,
    menuItems: [],
    soundTimer: null
  };

  constructor(props) {
    super(props);

    this.menuSoundRef = React.createRef();
    this.state.menuItems = [
      {
        title: 'Training',
        clickHandler: () => { this.props.handleTrainingClick() }
      },
      {
        title: 'vs.mode',
        image: Beta,
        clickHandler: () => { this.props.onGameClick() }
      },
      {
        title: 'About',
        clickHandler: () => { 
          setTimeout(() => {
            if (this.props.onOptionsClick) {
              this.props.onOptionsClick();
            }
          }, 600);
         }
      }
    ]
  }

  componentWillUpdate(prevProps) {
    if(!this.getIsMenuPage()) {
      return;
    }

    if(this.props.clickedDirection !== prevProps.clickedDirection) {
      switch(this.props.clickedDirection) {
        case MOVE_DOWN: {
          if(this.state.active < this.state.menuItems.length - 1) {
            this.activateMenuItem(this.state.active + 1)
          }
          break;
        }
        case MOVE_TOP: {
          if(this.state.active > 0) {
            this.activateMenuItem(this.state.active - 1)
          }
          break;
        }
        default: {
          break;
        }
      }
    }
    if(this.props.selectPressed !== prevProps.selectPressed && this.props.selectPressed) {
      this.goToSelectedItem();
    }
  }

  activateMenuItem = (index) => {
    if(this.state.active === index) {
      return;
    }

    this.setState({
      active: index
    });
  }

  deactivateMenuItem = (index) => {
    if(this.state.active === index) {
      this.setState({
        active: -1
      });
    }
  }

  getIsMenuPage = () => {
    return this.props.currentStep === viewSteps.indexOf(START_VIEW)
  }

  goToSelectedItem = (index = this.state.active) => {
    this.menuSoundRef.current.play();
    
    const stateUpdates = {
      selecting: true
    }

    if (index !== this.state.active) {
      stateUpdates.active = index;
    }

    this.setState({
      ...stateUpdates
    });

    setTimeout(() => {
      this.setState({
        selecting: false
      });
    }, 600);

    if(this.state.menuItems[index] && this.state.menuItems[index].clickHandler) {
      this.state.menuItems[index].clickHandler();
    }
  }

  handleMenuItemClick = (index) => {
    this.goToSelectedItem(index);
  }

  render() {
    const { active } = this.state;

    return (
      <>
        <Logo />
        <AudioPlayer ref={this.menuSoundRef} file={menuElementsSounds} />
        <div className={`menu ${this.props.className ? this.props.className : ''} ${this.state.selecting ? 'selecting' : ''} ${this.state.deactivating ? 'deactivating' : ''}`}>
          {
            this.state.menuItems.map((item, index) => (
              <button
                aria-label={item.title}
                key={`menu-item-${index}-${item.title}`}
                className={`menu-item ${(active === (index)) ? 'active' : ''}  ${item.image ? 'menu-item--with-image' : '' }`}
                onClick={() => this.handleMenuItemClick(index)}
                onMouseOver={() => this.activateMenuItem(index)}
                onMouseLeave={() => this.deactivateMenuItem(index)}
              >
                <div className="menu-item-content">
                  <span>{item.title}</span>
                  <span>{item.title}</span>
                </div>
                {
                  item.image && 
                  <img alt={item.title} src={item.image}/>
                }
              </button>
            ))
          }
        </div>
      </>
    );
  }
}

export default MenuElements;
