import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import debounce from 'lodash.debounce';

import CustomCursor from '../../components/CustomCursor/CustomCursor';
import constantValues from '../../helpers/constants';
import AudioToggle from '../../components/AudioPlayer/AudioToggle';

const BackgroundImage = styled.img`
  position: absolute;
  z-index: 1;
  transition: transform 2s ease-in-out;
`;

const AppLoader = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  animation: ${({ animation }) => animation};
  z-index: 1000;
`;

const RoomContainer = styled.div`
  position: relative;
  left: ${({ initialRoomLeft }) => initialRoomLeft}px;
  top: ${({ initialRoomTop }) => initialRoomTop}px;
  width: auto;
  display: inline-block;
  transition: transform 3s ease-in-out;
  overflow: hidden;
`;

const RoomBgc = styled.img`
  height: 110vh;
  min-width: 100vw;
  transition: transform 2s ease-in-out;
`;

const MessageBox = styled.div`
  position: absolute;
  z-index: 102;
  bottom: 2%;
  left: 58vw;
  height: 40px;
  width: 240px;
  transform: translateX(-50%);
  animation: ${({ animate }) => (animate ? 'fade-out 0.7s ease 3s forwards' : 'none')}
  border-radius: 10px;
  font-size: 20px;
  text-align: center;
  background: #272727;
  line-height: 40px;
  color: #fff;
`;

const Lights = styled.img`
  height: 110vh;
  min-width: 100vw;
  transition: transform 2s ease-in-out;
  position: absolute;
  bottom: 0;
  left: -6px;
  z-index: 11;
  opacity: 0.8;
  animation: fade-light-in 1.5s ease 2s forwards;
  pointer-events: none;
`;

const Lamp = styled(BackgroundImage)`
  height: 70%;
  z-index: 101;
  bottom: 0;
  left: 12%;
`;

const Car = styled(BackgroundImage)`
  height: 10%;
  bottom: 5%;
  left: 20%;
`;

const Pencil = styled(BackgroundImage)`
  height: 4%;
  bottom: 3%;
  left: 50%;
`;

const Crayon = styled(BackgroundImage)`
  height: 3%;
  bottom: 7%;
  left: 34%;
`;

const Discs = styled(BackgroundImage)`
  height: 15%;
  bottom: 4%;
  left: 51%;
`;

const Computer = styled(BackgroundImage)`
  left: 60%;
  bottom: 0;
  z-index: 4;
  max-width: 100%;
  max-height: 90%;
  pointer-events: none;
`;

const ComputerContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 60%;
  height: 90%;
  width: ${({ scale }) => scale * (constantValues.screenWidth + 156)}px;
  display: inline-block;
  transition: transform 2s ease-in-out;
`;

const AudioToggleWrapper = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 105vw;
`;

const debouncer = debounce(f => f(), 10);

const numberOfImagesInPage = 8;

const getContainerTranslateX = (offsetLeft, innerWidth) => {
  if (offsetLeft) {
    return -(innerWidth / 2 + offsetLeft - innerWidth);
  }

  return 0;
};

const AppBackground = props => {
  const [initialRoomLeft, setInitialRoomLeft] = useState(-(document.body.clientWidth * 0.08));
  const [initialRoomTop, setInitialRoomTop] = useState(-(document.body.clientHeight * 0.1));
  const [xPos, setXPos] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const [loadedImageCounter, setLoadedImageCounter] = useState(0);
  const [translateValue, setTranslateValue] = useState(0);
  const [cursorPos, setCursorPos] = useState({
    x: 0,
    y: 0,
  });
  const [isGameLoading, setIsGameLoading] = useState(false);
  const [isGame, setIsGame] = useState(false);
  const [isGameView, setIsGameView] = useState(false);
  const [gameLoadingStarted, setGameLoadingStarted] = useState(false);
  const [moveToGame, setMoveToGame] = useState(false);
  const roomContainerRef = useRef();
  const containerOffsetLEft = roomContainerRef.current && roomContainerRef.current.offsetLeft;
  const roomContainerTranslateX = useMemo(
    () => getContainerTranslateX(containerOffsetLEft, window.innerWidth),
    [containerOffsetLEft],
  );
  const documentResize = window.addEventListener('resize', () => {
    setInitialRoomLeft(-(document.body.clientWidth * 0.08));
    setInitialRoomTop(-(document.body.clientHeight * 0.1));
  });

  useEffect(
    () =>
      debouncer(() => {
        const { x } = cursorPos;

        if (!xPos) {
          return setXPos(x);
        }

        if (xPos + 150 < x) {
          setTranslateValue(-3);
          setXPos(x);
        } else if (xPos - 150 > x) {
          setTranslateValue(3);
          setXPos(x);
        }
      }),
    [cursorPos],
  );

  useEffect(() => {
    if (numberOfImagesInPage <= loadedImageCounter) {
      setLoading(false);
      setTimeout(() => setShowLoader(false), 1000);
    }

    return () => {
      window.removeEventListener('resize', documentResize);
    };
  }, [loadedImageCounter]);

  const handleMouseMove = e => {
    setCursorPos({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleClick = () => {
    setMoveToGame(true);
    setGameLoadingStarted(true);
    setTimeout(() => {
      setIsGameLoading(true);
      setGameLoadingStarted(false);
    }, 1000);
    setTimeout(() => {
      setIsGameLoading(false);
      props.renderGameOnComputer();
      setIsGameView(true);
    }, 3000);
  };

  const handleImageLoad = () => {
    setLoadedImageCounter(loadedImageCounter + 1);
  };

  return (
    <div onMouseMove={e => handleMouseMove(e)} onClick={handleClick}>
      {showLoader && (
        <AppLoader animation={loading ? 'none' : 'fade-out 0.7s ease 0.2s forwards'} />
      )}
      <RoomContainer
        initialRoomTop={initialRoomTop}
        initialRoomLeft={initialRoomLeft}
        style={{
          transform: moveToGame ? `translateX(${roomContainerTranslateX}px)` : 'none',
        }}
      >
        <RoomBgc
          src={require('../../assets/img/mainView/BCKG-min.jpg')}
          alt="room background"
          style={{
            transform: moveToGame ? 'none' : `translateX(${translateValue}vw)`,
          }}
          onLoad={handleImageLoad}
        />
        <Lamp
          src={require('../../assets/img/mainView/Lamp-min.png')}
          alt="lamp"
          style={{
            transform: moveToGame ? 'none' : `translateX(${translateValue * 1.2}vw)`,
          }}
          onLoad={handleImageLoad}
        />
        <Car
          src={require('../../assets/img/mainView/auto-min.png')}
          alt="car toy"
          style={{
            transform: moveToGame ? 'none' : `translateX(${translateValue * 1.05}vw)`,
          }}
          onLoad={handleImageLoad}
        />
        <Crayon
          src={require('../../assets/img/mainView/crayon-min.png')}
          alt="crayon"
          style={{
            transform: moveToGame ? 'none' : `translateX(${translateValue * 1.05}vw)`,
          }}
          onLoad={handleImageLoad}
        />
        <Discs
          src={require('../../assets/img/mainView/discs-min.png')}
          alt="discs"
          style={{
            transform: moveToGame ? 'none' : `translateX(${translateValue * 1.05}vw)`,
          }}
          onLoad={handleImageLoad}
        />
        <Pencil
          src={require('../../assets/img/mainView/Pencil-min.png')}
          alt="pencil"
          style={{
            transform: moveToGame ? 'none' : `translateX(${translateValue * 1.05}vw)`,
          }}
          onLoad={handleImageLoad}
        />
        <MessageBox animate={!showLoader}>Click anywhere to start</MessageBox>
        <Lights
          src={require('../../assets/img/mainView/Lights-min.png')}
          alt="lights"
          style={{
            transform: moveToGame ? `translateX(${-3}vw)` : `translateX(${translateValue * 1.1}vw)`,
          }}
          onLoad={handleImageLoad}
        />
        <Computer
          src={require('../../assets/img/mainView/ComputerEmpty-min.png')}
          alt="computer"
          style={{
            transform: moveToGame
              ? `translateX(${-3}vw)`
              : `translateX(${translateValue * 1.05}vw)`,
          }}
          onLoad={handleImageLoad}
        />
        <ComputerContainer
          scale={props.scale}
          onMouseOver={() => setIsGame(true)}
          onMouseLeave={() => setIsGame(false)}
          ref={roomContainerRef}
          style={{
            transform: moveToGame
              ? `translateX(${-3}vw)`
              : `translateX(${translateValue * 1.05}vw)`,
          }}
          onLoad={handleImageLoad}
        >
          {props.children}
        </ComputerContainer>
        <AudioToggleWrapper
          style={{
            transform: `translateX(${roomContainerTranslateX * -1}px)`,
          }}
        >
          <AudioToggle showBackground />
        </AudioToggleWrapper>
        {showLoader ? (
          <CustomCursor
            initialRoomLeft={initialRoomLeft}
            initialRoomTop={initialRoomTop}
            isOverLayers
            cursorPos={cursorPos}
            isAppLoading={true}
            animation={loading ? 'none' : 'fade-out 0.7s ease 0.2s forwards'}
          />
        ) : (
          <CustomCursor
            initialRoomLeft={initialRoomLeft}
            initialRoomTop={initialRoomTop}
            roomLeft={moveToGame ? roomContainerTranslateX : 0}
            cursorPos={cursorPos}
            isAppLoading={showLoader}
            gameLoadingStarted={gameLoadingStarted}
            isGameLoading={isGameLoading}
            mouseOnGameBox={isGame}
            isGameView={isGameView}
          />
        )}
      </RoomContainer>
    </div>
  );
};

export default AppBackground;
