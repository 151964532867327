import React from 'react';

function AvatarSliderSel(props) {
  const { avatar, classes } = props;

  return (
    <div className={"carousel-item avatar-carousel-item " + classes}>
      <img alt="avatar" src={avatar} className="avatar-carousel-item__avatar" />
    </div>
  );
}

export default AvatarSliderSel;
