import React from "react";

function TextRectangle(props) {

    return (
        <>
        <div className={`text-rect ${props.className}`}>
            <span className="text-rect__text">{props.text}</span>
            <svg width="124px" height="43px" viewBox="0 0 124 43" version="1.1">
                <title>red-name</title>
                <desc>Created with Sketch.</desc>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="iPhone-XS-Copy-40" transform="translate(-37.000000, -362.000000)">
                        <g id="Group-19" transform="translate(37.000000, 321.000000)">
                            <g id="red-name" transform="translate(0.000000, 41.000000)">
                                <g id="Group-5">
                                    <rect id="Rectangle" fill="#F12977" x="3.5177305" y="0" width="116.085106" height="43"></rect>
                                    <rect id="Rectangle" fill="#F12977" x="0" y="4.47916667" width="124" height="34.0416667"></rect>
                                    <rect id="Rectangle" fill="#1E0C3B" x="3.5177305" y="4.47916667" width="116.085106" height="34.0416667"></rect>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        </>
    );
}

export default TextRectangle;
