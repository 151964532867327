export const userStartMessages = ['Let\'s go dark', 'Hey you!', 'Uuuuu', 'Eye on me!', 'I am', 'Hello', 'Piiii', 'Bye Bye', 'Blast \'em', 'Valhalla awaits', 'Ameamamaha'];
export const userFailureMessages = ['', '', '', '', '', '', '', '', '', ''];
export const userSuccessMessages = ['Let\'s go dark', 'Bye bye', 'Uuueeeyyy!', 'Now you see!', 'I am', 'Good job', 'Holy grandes!', 'uahahaha', 'Empire!', 'Arghhhhh!', 'you lost!'];

export const getStartMessage = (enemyIndex = 0) => {
  return enemyIndex !== null ? userStartMessages[enemyIndex] : userStartMessages[0];
}

export const getFailureMessage = (enemyIndex = 0) => {
  return null;
}

export const getSuccessMessage = (enemyIndex = 0) => {
  return enemyIndex !== null ? userSuccessMessages[enemyIndex] : userSuccessMessages[0];
}