import React, { useRef } from 'react';

import MainButton from "../MainButton/MainButton";
import Message from "../Message/Message";
import arrowBackSound from '../../assets/sounds/Strzaleczka-bakc.wav';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

const NoUserScreen = (props) => {
  const arrowBack = useRef(null);

  const handleGoBack = () => {
    arrowBack.current.play();
    props.handleGoBack();
  }
  
  return (
    <div className="not-found">
      <Message title="sorry">
        {props.children}
      </Message>
      <MainButton onClick={props.hideMessage} text="Try again"></MainButton>
      <AudioPlayer ref={arrowBack} file={arrowBackSound} />
      <button aria-label="Back to menu" onClick={handleGoBack} className="not-found__back-button">Back to menu</button>
    </div>
  )
}

export default NoUserScreen;