import React from 'react';

import Message from "../Message/Message";

import './style.scss';

const DesktopOnlyScreen = () => {
  return (
    <div className="desktop-only-screen">
      <Message fillColor="#fff" title="sorry">
        Please use desktop site to get full gaming experience.
        <br/>
        <br/>
        Responsive version is comming soon.
      </Message>
    </div>
  )
}

export default DesktopOnlyScreen;