import React from 'react';

import bangBcg from '../../assets/svg/bang-bck.svg';

import constants from '../../helpers/constants';

const { gamePanelDimensions } = constants;
const message = 'bang';

const GamePanel = props => {
  let availableDimensions = { ...props.gamePanelDimensions };

  if (props.dimensions) {
    availableDimensions = { ...props.dimensions };
  }

  return (
    <div className="game__panel">
      <>
        {[...Array(gamePanelDimensions.y - availableDimensions.y)].map((yElement, yIndex) => (
          <div
            key={`{row-${yIndex}`}
            className="game__panel-row game__panel-row--unavailable"
          ></div>
        ))}
      </>
      {props.rowsChanged && (
        <div className="bang">
          <img className="bang__bcg" src={bangBcg} aria-label="bang bcg"></img>
          <p>{message}</p>
        </div>
      )}
      <>
        {[...Array(availableDimensions.y)].map((yElement, yIndex) => (
          <div key={`{row-${yIndex}`} className="game__panel-row">
            {[...Array(availableDimensions.x)].map((xElement, xIndex) => (
              <span key={`{col-${xIndex}`} className="game__panel-col"></span>
            ))}
          </div>
        ))}
      </>
    </div>
  );
};

export default GamePanel;
