import React, { useEffect, useState, useImperativeHandle } from 'react';
import QRCode from 'qrcode.react';

import './style.scss';
import MatchingScreen from './MatchingScreen/MatchingScreen';
import { appUrl } from '../../helpers/constants';
import LogoDashes from '../Logo/LogoDashes';
import NoUserScreen from '../NoUserScreen/NoUserScreen';

const UserMatchingScreen = (props) => {
  const { nickname, showQrCode, padId, randomHash, stopUserFetching, reference, enemyMatched, restartUserFetching, pauseUserFetching } = props;
  const [messageTimer, setMessageTimer] = useState(null);
  const [showNoUserScreen, setShowNoUserScreen] = useState(false);

  useImperativeHandle(reference, () => ({
    handleBackArrow() {
      if(showNoUserScreen) {
        handleGoBack();
      }
    },
    handleMessageClose() {
      if(showNoUserScreen) {
        hideMessage();
      }
    }
  }));

  useEffect(() => {
    if(!showQrCode) {
      initMessageTimer();
    }

    return clearMessageTimer;
  }, [showQrCode]);

  const clearMessageTimer = () => {
    if(messageTimer !== null) {
      clearTimeout(messageTimer);
      setMessageTimer(null);
    }
  }

  const hideMessage = () => {
    setShowNoUserScreen(false);
    initMessageTimer();
    restartUserFetching();
  }

  const initMessageTimer = () => {
    const timer = setTimeout(() => {
      setShowNoUserScreen(true);
      pauseUserFetching();
    }, 20000);
    setMessageTimer(timer);
  }

  const handleGoBack = () => {
    setShowNoUserScreen(false);
    stopUserFetching();
  }

  return (
    <div className="user-matching">
      {showQrCode ? (
          <div className="qr-wrapper">
            <div className="qr-caption">
              <div className="qr-dashes">
                <LogoDashes dashesAmount={2} logoWidth={25}></LogoDashes>
              </div>
              <p className="qr-title">Scan</p>
              <div className="qr-dashes">
                <LogoDashes dashesAmount={2} logoWidth={25}></LogoDashes>
              </div>
            </div>
            <div className="qr-container">
              <QRCode size={250} fgColor="#000000" bgColor="#FFFFFF" value={`${appUrl}/controller?nickname=${nickname}&padId=${padId + 1}&randomHash=${randomHash}`} />
            </div>
            <p className="qr-comment">Scan the code with your smartphone</p>
          </div>
      ) : (
        <>
          <MatchingScreen />
            {
              (showNoUserScreen && !enemyMatched) &&
              <NoUserScreen hideMessage={hideMessage}  handleGoBack={handleGoBack}>
                <>
                  OOOPS…! 
                  <br/>
                  No players available :(
                  <br/>
                  Try again or choose training! 
                </>
              </NoUserScreen>
            }
        </>
      )}
    </div>
  );
}

export default UserMatchingScreen;
