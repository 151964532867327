import { blocks, tetrisBlockColors } from './constants';
import { MOVE_LEFT } from './events';

export const computeScores = scores => {
  let scoresArray = [];

  if (scores < 10) {
    scoresArray = [0, 0, scores];
  } else if (scores < 100) {
    scoresArray = [0, Math.floor(scores / 10), scores % 10];
  } else {
    scoresArray = [Math.floor(scores / 100), Math.floor((scores % 100) / 10), scores % 10];
  }

  return scoresArray;
};

const getWithoutEmptyRows = block => {
  let tmpBlock = block.filter(row => row.some(cell => cell));

  for (let i = 0; i < tmpBlock.length; i++) {
    let isEmpty = true;

    for (let j = 0; j < tmpBlock.length; j++) {
      isEmpty = isEmpty && !tmpBlock[j][i];
    }

    if (isEmpty) {
      tmpBlock = tmpBlock.map(row => row.filter((cell, index) => index !== i));
    }
  }

  return tmpBlock.filter(row => row.some(cell => cell));
};

const getWithoutEmptyCols = block => {
  let tmpBlock = block.filter(row => row.some(cell => cell));

  for (let i = 0; i < tmpBlock[0].length; i++) {
    let isEmpty = true;

    for (let j = 0; j < tmpBlock.length; j++) {
      isEmpty = isEmpty && !tmpBlock[j][i];

      if (!isEmpty) {
        continue;
      }
    }

    if (isEmpty) {
      tmpBlock = tmpBlock.map(row => row.filter((cell, index) => index !== i));
    }
  }

  return tmpBlock;
};

// inspired by Michal Zuk
export const getRandomBlock = () => {
  let block = [...blocks[Math.floor(Math.random() * blocks.length)]];
  let tmpBlock = getFilteredBlockLeft(block);
  let randomColor = tetrisBlockColors.pink;
  const colors = Object.keys(tetrisBlockColors);

  randomColor = tetrisBlockColors[colors[Math.floor(Math.random() * colors.length)]];

  if (Math.random() > 0.5) {
    for (let i = 0; i < Math.floor(Math.random() * 4); i++) {
      tmpBlock = getFilteredBlockLeft(block);
    }
  } else {
    for (let i = 0; i < Math.floor(Math.random() * 4); i++) {
      tmpBlock = getFilteredBlockRight(block);
    }
  }

  return {
    block: [...block],
    displayBlock: [...tmpBlock],
    color: randomColor,
  };
};

export const getRandomHash = (length) => {
  let randString = Math.random().toString(32);

  randString = randString.slice(2);

  while(randString.length < 0) {
    randString += Math.random().toString(32).slice(2);
  }

  return randString.slice(0, length);
};

export const getRotatedBlock = (block, side) => {
  let rotatedFilteredBlock;
  let rotatedBlock = [...block];

  switch (side) {
    case MOVE_LEFT: {
      rotatedBlock = rotateBlockLeft(rotatedBlock);
      break;
    }
    default: {
      rotatedBlock = rotateBlockRight(rotatedBlock);
      break;
    }
  }

  rotatedFilteredBlock = getWithoutEmptyCols(getWithoutEmptyRows(rotatedBlock));

  return {
    block: [...rotatedBlock],
    displayBlock: [...rotatedFilteredBlock],
  };
};

export const getFilteredBlockRight = block => {
  const tmpBlock = [...block];
  rotateBlockRight(tmpBlock);

  return getWithoutEmptyCols(getWithoutEmptyRows(tmpBlock));
};

export const getFilteredBlockLeft = block => {
  const tmpBlock = [...block];
  rotateBlockLeft(tmpBlock);

  return getWithoutEmptyCols(getWithoutEmptyRows(tmpBlock));
};

// inspired by Michal Zuk :) 
const rotateBlockLeft = block => [
  [block[0][3], block[1][3], block[2][3], block[3][3]],
  [block[0][2], block[1][2], block[2][2], block[3][2]],
  [block[0][1], block[1][1], block[2][1], block[3][1]],
  [block[0][0], block[1][0], block[2][0], block[3][0]],
];

const rotateBlockRight = block => [
  [block[3][0], block[2][0], block[1][0], block[0][0]],
  [block[3][1], block[2][1], block[1][1], block[0][1]],
  [block[3][2], block[2][2], block[1][2], block[0][2]],
  [block[3][3], block[2][3], block[1][3], block[0][3]],
];
