import React from "react";

import './style.scss';

const Building = (props) => {
  return (
    <>
      <svg
        className="building"
        viewBox="0 0 340 676"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        {/*     <style>         * { cursor: none; user-select: none; }     </style><!--Generator: Sketch 54.1 (76490) - https://sketchapp.com --> */}
        <title>Group 4</title>
        <desc>Created with Sketch.</desc>
        <defs>
          <linearGradient
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            id="linearGradient-1"
          >
            <stop stopColor="#200544" stopOpacity="0" offset="0%" />
            <stop stopColor="#261B52" offset="100%" />
          </linearGradient>
          <linearGradient
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            id="linearGradient-2"
          >
            <stop stopColor="#200544" stopOpacity="0" offset="0%" />
            <stop stopColor="#261B52" offset="100%" />
          </linearGradient>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="player-copy" transform="translate(-26.000000, -27.000000)">
            <g id="Group-4" transform="translate(26.000000, 27.000000)">
              <g id="Group-3" transform="translate(289.000000, 3.000000)">
                <polygon
                  id="Rectangle"
                  fill="#6348A3"
                  points="0 30 9.72 30 9.72 146.296296 0 146.296296"
                />
                <rect
                  id="Rectangle"
                  fill="#6348A3"
                  x="0"
                  y="154.925926"
                  width="9.72"
                  height="4.31481481"
                />
                <rect
                  id="Rectangle-Copy-77"
                  fill="#6348A3"
                  x="0"
                  y="331.833333"
                  width="9.72"
                  height="141.310185"
                />
                <rect
                  id="Rectangle-Copy-77"
                  fill="#6348A3"
                  x="0"
                  y="478.537037"
                  width="9.72"
                  height="166"
                />
                <rect
                  id="Rectangle-Copy-84"
                  fill="#6348A3"
                  x="0"
                  y="176.5"
                  width="9.72"
                  height="152.097222"
                />
                <rect
                  id="Rectangle-Copy-77"
                  fill="#392A74"
                  x="0"
                  y="328.597222"
                  width="9.72"
                  height="4.31481481"
                />
                <rect
                  id="Rectangle-Copy-77"
                  fill="#4D4693"
                  x="2.16"
                  y="329.675926"
                  width="6.48"
                  height="2.15740741"
                />
                <rect
                  id="Rectangle-Copy-77"
                  fill="#6348A3"
                  x="0"
                  y="170.027778"
                  width="9.72"
                  height="4.31481481"
                />
                <rect
                  id="Rectangle-Copy-78"
                  fill="#6348A3"
                  x="0"
                  y="164.634259"
                  width="9.72"
                  height="4.31481481"
                />
                <rect
                  id="Rectangle-Copy-80"
                  fill="#42228D"
                  x="0"
                  y="161.398148"
                  width="9.72"
                  height="4.31481481"
                />
                <rect
                  id="Rectangle-Copy-81"
                  fill="#42228D"
                  x="0"
                  y="166.791667"
                  width="9.72"
                  height="4.31481481"
                />
                <rect
                  id="Rectangle-Copy-82"
                  fill="#42228D"
                  x="0"
                  y="173.263889"
                  width="9.72"
                  height="4.31481481"
                />
                <rect
                  id="Rectangle-Copy-83"
                  fill="#42228D"
                  x="0"
                  y="151.689815"
                  width="9.72"
                  height="4.31481481"
                />
                <rect
                  id="Rectangle-Copy-83"
                  fill="#35266C"
                  x="0"
                  y="146.296296"
                  width="9.72"
                  height="5.39351852"
                />
                <rect
                  id="Rectangle-Copy-79"
                  fill="#B062DB"
                  x="0"
                  y="158.162037"
                  width="9.72"
                  height="4.31481481"
                />
                <polygon
                  id="Rectangle-Copy-64"
                  fill="#B062DB"
                  points="8.64 30 14.04 30 14.04 282.51528 11.2565039 282.51528 11.2565039 284.383985 9.9155127 284.383985 9.9155127 311.337963 8.64 311.337963"
                />
                <polygon
                  id="Rectangle-Copy-65"
                  fill="#6730A1"
                  points="15.12 30 17.28 30 17.28 266.032407 15.12 266.032407"
                />
                <rect
                  id="Rectangle"
                  fill="#4D3176"
                  x="31.24"
                  y="126.273148"
                  width="5.4"
                  height="5.39351852"
                />
                <rect
                  id="Rectangle"
                  fill="#4D3176"
                  x="31.24"
                  y="133.824074"
                  width="2.16"
                  height="2.15740741"
                />
                <rect
                  id="Rectangle-Copy-74"
                  fill="#4D3176"
                  x="34.48"
                  y="133.824074"
                  width="2.16"
                  height="2.15740741"
                />
                <rect
                  id="Rectangle-Copy-72"
                  fill="#4D3176"
                  x="31.24"
                  y="109.013889"
                  width="5.4"
                  height="14.0231481"
                />
                <rect
                  id="Rectangle-Copy-73"
                  fill="#4D3176"
                  x="31.24"
                  y="103.62037"
                  width="5.4"
                  height="4.31481481"
                />
                <polygon
                  id="Path-2"
                  fill="#4E2081"
                  points="19.68 82.5044016 27.1554453 82.5044016 27.1554453 1.35661801e-15 34.474445 1.35661801e-15 34.474445 82.9076088 40.0972988 82.9076088 40.0972988 1.35661801e-15 51 1.35661801e-15 51 97.7546296 20.3313037 97.7546296"
                />
                <rect
                  id="Rectangle-Copy-66"
                  fill="#4E1F81"
                  x="16.2"
                  y="20"
                  width="5.4"
                  height="238.481481"
                />
                <polygon
                  id="Path-3"
                  fill="#7136AF"
                  points="51 83.7314815 44.9535531 83.7314815 44.9535531 82.0949568 43.44 82.0949568 43.44 62.9660572 44.4655688 62.9660572 44.4655688 61.0787037 51 61.0787037"
                />
                <rect
                  id="Rectangle"
                  fill="#56248A"
                  x="44.52"
                  y="66.4722222"
                  width="2.16"
                  height="1.0787037"
                />
                <rect
                  id="Rectangle-Copy-75"
                  fill="#56248A"
                  x="44.52"
                  y="70.787037"
                  width="2.16"
                  height="1.0787037"
                />
                <rect
                  id="Rectangle-Copy-76"
                  fill="#56248A"
                  x="44.52"
                  y="77.2592593"
                  width="2.16"
                  height="1.0787037"
                />
              </g>
              <polygon
                id="Rectangle"
                fill="#35266C"
                points="37 3 294 3 294 668 37 668"
              />
              <rect
                id="Rectangle"
                fill="url(#linearGradient-1)"
                opacity="0.217866443"
                x="38"
                y="529"
                width="256"
                height="139"
              />
              <rect
                id="Rectangle"
                fill="url(#linearGradient-2)"
                opacity="0.217866443"
                transform="translate(163.000000, 89.500000) scale(1, -1) translate(-163.000000, -89.500000) "
                x="37"
                y="3"
                width="252"
                height="173"
              />
              <rect
                id="Rectangle"
                fill="#4F2083"
                x="0"
                y="149"
                width="15"
                height="15"
              />
              <rect
                id="Rectangle"
                fill="#4F2083"
                x="18"
                y="2"
                width="8"
                height="181"
              />
              <rect
                id="Rectangle"
                fill="#4F2083"
                x="0"
                y="139"
                width="15"
                height="15"
              />
              <rect
                id="Rectangle"
                fill="#4F2083"
                x="0"
                y="0"
                width="11"
                height="155"
              />
              <rect
                id="Rectangle"
                fill="#7542B1"
                x="15"
                y="108"
                width="3"
                height="75"
              />
              <rect
                id="Rectangle"
                fill="#7542B1"
                x="15"
                y="92"
                width="3"
                height="12"
              />
              <rect
                id="Rectangle"
                fill="#7542B1"
                x="15"
                y="87"
                width="3"
                height="3"
              />
              <polygon
                id="Rectangle"
                fill="#6348A3"
                points="294 27 304 27 304 662 294 662"
              />
              <rect
                id="Rectangle"
                fill="#6348A3"
                x="93"
                y="248"
                width="4"
                height="5"
              />
              <rect
                id="Rectangle-Copy-4"
                fill="#6348A3"
                x="100"
                y="259"
                width="10"
                height="8"
              />
              <rect
                id="Rectangle-Copy-93"
                fill="#6348A3"
                x="145"
                y="334"
                width="9"
                height="8"
              />
              <rect
                id="Rectangle-Copy-94"
                fill="#6348A3"
                x="80"
                y="494"
                width="10"
                height="8"
              />
              <rect
                id="Rectangle-Copy-4"
                fill="#6348A3"
                x="149"
                y="436"
                width="10"
                height="3"
              />
              <polygon
                id="Rectangle-Copy"
                fill="#6348A3"
                points="27 259 37 259 37 434 27 434"
              />
              <polygon
                id="Rectangle-Copy-2"
                fill="#6348A3"
                points="27 431 32.4715402 431 32.5341797 432.741935 37 432.741935 37 667 27 667"
              />
              <rect
                id="Rectangle"
                fill="#2E2060"
                x="27"
                y="581"
                width="10"
                height="2"
              />
              <rect
                id="Rectangle-Copy-56"
                fill="#7559B5"
                x="27"
                y="580"
                width="10"
                height="1"
              />
              <polygon
                id="Path"
                fill="#7136AF"
                points="6 111 1 111 1 106.667437 2.82006945 106.667437 2.82006945 104.683575 1 104.683575 1 102.153646 2.82006945 102.153646 2.82006945 99.5396196 1 99.5396196 1 93.217805 2.63237295 93.217805 2.63237295 91.2925975 1 91.2925975 1 88.648116 2.63237295 88.648116 2.63237295 86.8591431 1 86.8591431 1 86 6 86"
              />
              <polygon
                id="Rectangle"
                fill="#9B7CE3"
                points="26 2 29 2 29 255 26 255"
              />
              <g id="Group-20" transform="translate(15.000000, 2.000000)">
                <g id="Group-22">
                  <polygon
                    id="Rectangle"
                    fill="#43347A"
                    points="10 -3.63797881e-12 16 -3.63797881e-12 16 667.97937 13.0289307 667.97937 13.0289307 671 10 671"
                  />
                  <polygon
                    id="Rectangle"
                    fill="#231D3A"
                    points="16 2.95600241e-12 22 2.95600241e-12 22 663.567742 19.0422363 663.567742 19.0422363 666 16 666"
                  />
                  <polygon
                    id="Rectangle"
                    fill="#7257B4"
                    points="0 0 10 0 10 674 0 674"
                  />
                </g>
                <g
                  id="Group-22"
                  transform="translate(285.000000, 337.500000) scale(-1, 1) translate(-285.000000, -337.500000) translate(274.000000, 1.000000)"
                >
                  <polygon
                    id="Rectangle"
                    fill="#43347A"
                    points="10 -2.32452946e-15 16 -2.32452946e-15 15.9710693 667.010132 13 667.010132 13.0289307 670 10 670"
                  />
                  <polygon
                    id="Rectangle"
                    fill="#231D3A"
                    points="16 3.63797881e-12 22 3.63797881e-12 22 662.795735 19.0422363 662.795735 19.0422363 665 16 665"
                  />
                  <polygon
                    id="Rectangle"
                    fill="#7257B4"
                    points="0 0 10 0 10 673 0 673"
                  />
                </g>
                <polygon
                  id="Rectangle"
                  fill="#231D3A"
                  transform="translate(147.500000, 662.500000) rotate(90.000000) translate(-147.500000, -662.500000) "
                  points="144 531 151 531 151 792.672524 147.549276 792.672524 147.549276 794 144 794"
                />
              </g>
              {
                props.default ? 
                null 
                :
                <g
                  id="Group-23"
                  transform="translate(37.000000, 154.037037)"
                  stroke="#4A3482"
                >
                  <path d="M1.25055521e-12,404.5 L252,404.5" id="Path-24" />
                  <path d="M1.25055521e-12,264.5 L252,264.5" id="Path-24" />
                  <path d="M1.25055521e-12,141.5 L252,141.5" id="Path-24" />
                  <path
                    d="M-141,202.5 L263,202.5"
                    id="Path-24"
                    transform="translate(61.000000, 202.500000) rotate(90.000000) translate(-61.000000, -202.500000) "
                  />
                  <path
                    d="M-11,202.5 L393,202.5"
                    id="Path-24"
                    transform="translate(191.000000, 202.500000) rotate(90.000000) translate(-191.000000, -202.500000) "
                  />
                  <path d="M1.25055521e-12,0.5 L252,0.5" id="Path-24" />
                </g>
              }
            </g>
          </g>
        </g>
      </svg>
      
    </>
  );
};

export default Building;
