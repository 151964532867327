import React, { useState, useEffect } from 'react';
import { pads } from '../../helpers/mainData';

import GamePadWrapper from './GamePadWrapper';

const GamePad = props => {
  const maxRationDimension = 375;
  const padBorerRadius = 14;
  const maxRation = maxRationDimension / 680;
  const [pad, setPad] = useState(null);
  const [vmin, setVmin] = useState(1);
  const [vminUnits, setVminUnits] = useState('vmin');
  const { padId, triggerFasterFall, socket, nickname, verticalOrientation } = props;

  useEffect(() => {
    changePadDimensions();
  }, []);

  useEffect(() => {
    if (isValidPadId(padId)) {
      setPad(pads[padId - 1]);
    }

    window.addEventListener('resize', changePadDimensions);
  }, [padId]);

  const changePadDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width > height) {
      updateViewportValues(height, width);
    } else {
      updateViewportValues(width, height);
    }
  };

  const updateViewportValues = (minDimension, maxDimension) => {
    if (minDimension / maxDimension > maxRation) {
      setVmin((maxDimension * maxRation) / 100);
      setVminUnits('px');
    } else {
      setVmin(1);
      setVminUnits('vmin');
    }
  };

  const isValidPadId = padId => {
    return padId !== null && !isNaN(padId) && padId > 0 && padId <= 4;
  };

  const move = direction => {
    if (props.move) {
      props.move(direction);
    }
  };

  const rotate = direction => {
    if (props.rotate) {
      props.rotate(direction);
    }
  };

  return (
    <GamePadWrapper
      vmin={vmin}
      vminUnits={vminUnits}
      triggerFasterFall={triggerFasterFall}
      showArrow={props.showArrow}
      move={move}
      rotate={rotate}
      socket={socket}
      nickname={nickname}
      verticalOrientation={verticalOrientation}
      pad={pad}
      randomHash={props.randomHash}
    >
      {pad !== null ? (
        <div
          style={{
            height: `${vmin * 100}${vminUnits}`,
            borderRadius: `${padBorerRadius * vmin}${vminUnits}`,
          }}
          className="game-pad__images"
        >
          <img
            src={require(`../../assets/img/pad${padId}/left.jpg`)}
            className="left-side"
            alt="pad-left-side"
          />
          <img
            src={require(`../../assets/img/pad${padId}/bg.jpg`)}
            className="padBgc"
            alt="pad-bgc"
          />
          <img
            src={require(`../../assets/img/pad${padId}/right.jpg`)}
            className="right-side"
            alt="pad-right-side"
          />
          {padId.toString() !== '2' && (
            <img
              src={require(`../../assets/img/pad${padId}/middle.png`)}
              className="middle"
              alt="pad-middle"
            />
          )}
        </div>
      ) : (
        <h2>No pad provided</h2>
      )}
    </GamePadWrapper>
  );
};

export default GamePad;
