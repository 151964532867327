import React from 'react';

import { AppConsumer } from '../../../AppContext';
import Spinner from '../../Spinner/Spinner';
import TextRectangle from '../../TextRectangle/TextRectangle';
import userMatchingLines from '../../../assets/svg/user-matching-lines.svg';
import vsIcon from '../../../assets/svg/vs.svg';
import cloud from '../../../assets/svg/say-white.svg';

import { avatars, avatarNames } from '../../../helpers/mainData';

import '../style.scss';

const MatchingScreen = () => {
  return (
    <AppConsumer>
      {props => {
        const { chosenAvatarIndex, nickname, enemy, enemyMessage, myMessage } = props;
        const enemyAvatarId = enemy ? avatarNames.indexOf(enemy.avatar) : -1;

        return (
          <div className="user-matching__container">
            <img
              className="user-matching__lines"
              src={userMatchingLines}
              type=""
              alt="user-matching"
            />
            <div className="user-matching__main-content">
              <div className="user-matching__section user-matching__user user-matching__section--top user-matching__user--top">
                <TextRectangle className="user-matching__user-name" text={nickname} />
                <img
                  className="user-matching__user-avatar"
                  src={chosenAvatarIndex >= 0 ? avatars[chosenAvatarIndex] : null}
                  type=""
                  alt="my avatar"
                />
                {((myMessage && enemyMessage) || (myMessage)) && (
                  <div className="user-matching__message user-matching__message--top">
                    <img
                      className="enemy-response__message-bcg"
                      src={cloud}
                      type=""
                      alt="text cloud"
                    />
                    <div className="enemy-response__message">{myMessage}</div>
                  </div>
                )}
              </div>
              <div className="user-matching__section">
                <img className="user-matching__vs" src={vsIcon} type="" alt="vs" />
              </div>
              <div className="user-matching__section">
                {enemy ? (
                  <>
                    {((myMessage && enemyMessage) || (myMessage)) && (
                      <div className="user-matching__message user-matching__message--bottom">
                        <img
                          className="enemy-response__message-bcg"
                          src={cloud}
                          type=""
                          alt="text cloud"
                        />
                        <div className="enemy-response__message">{enemyMessage || 'WHOAA!!!'}</div>
                      </div>
                    )}
                    <div className="user-matching__user user-matching__user--bottom">
                      <TextRectangle
                        className="user-matching__user-name enemy"
                        text={enemy.nickname}
                      />
                      <img
                        className="user-matching__user-avatar enemy"
                        src={enemyAvatarId >= 0 ? avatars[enemyAvatarId] : avatars[1]}
                        type=""
                        alt="my avatar"
                      />
                    </div>
                  </>
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
          </div>
        );
      }}
    </AppConsumer>
  );
};

export default MatchingScreen;
