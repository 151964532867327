import React from 'react';

const ArrowButton = props => {
  const { passedClasses, handleClickButton, animationEnd, isBottom } = props;
  const handleClick = () => {
    handleClickButton();
  };
  return (
    <div className={"btn-arrow__wrapper" + (isBottom ? " btn-arrow__wrapper--bottom" : "")}>
      <button aria-label={isBottom ? "Next slide" : "Previous slide"} className={passedClasses} onClick={handleClick} onAnimationEnd={animationEnd} />
    </div>
  )
};

export default ArrowButton;
