import React from 'react';

import LogoDashes from "../Logo/LogoDashes";

import './style.scss';

const Message = (props) => {
  const fillColor = props.fillColor ? props.fillColor : "#000";
  
  return (
    <div className="message">
      <div className="message__background"></div>
      <div className="message__heading">
        <LogoDashes fillColor={fillColor} dashesAmount={2} logoWidth="20"></LogoDashes>
          <span className="message__title">{props.title}</span>
        <LogoDashes fillColor={fillColor} dashesAmount={2} logoWidth="20"></LogoDashes>
      </div>
      <div className="message__content">
        <p className="message__text">{props.children}</p>
      </div>
    </div>
  )
}

export default Message;