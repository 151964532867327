import React from 'react';

import PadSliderUiLoaderSvg from './PadSliderUiLoaderSvg';
import PadSliderSel from './PadSliderSel';

import './style.scss';

import { pads } from '../../helpers/mainData';
import ArrowButton from '../Buttons/ArrowButton/ArrowButton';
import joypad from '../../assets/svg/joypad.svg';
import padBcg from '../../assets/svg/pad-background.svg';
import arrowClickSound from '../../assets/sounds/strzaleczki-wybor-padow-postaci-klik.wav';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

const events = require('../../helpers/events.js');
const {
  MOVE_DOWN,
  MOVE_TOP
} = events;
const initialPadId = 0;

const initialState = {
  buttonClasses: ['', ''],
  selectedPadId: initialPadId,
  classes: pads.map((pad, i) => (i === initialPadId ? 'pad-slide--curr' : '')),
  isSlideAnimated: false,
  animateArrowButtonUp: false,
  animateArrowButtonDown: false,
};

class PadSlider extends React.Component {
  sliderAudioRef;

  constructor(props) {
    super(props);

    this.state = { ...initialState };
    this.sliderAudioRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isSlideAnimated: true });
    }, 2000);
  }

  componentDidUpdate(prevProps) {
    this.checkPadMoveClicks(prevProps.clickedDirection);
  }

  checkPadMoveClicks = (prevClickDirection) => {
    if (this.props.clickedDirection !== prevClickDirection) {
      switch (this.props.clickedDirection) {
        case MOVE_DOWN: {
          this.handleClickButton('down');
          break;
        }
        case MOVE_TOP: {
          this.handleClickButton('up');
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  handleClickButton = buttonType => {
    if (buttonType === 'up') {
      this.setState({
        animateArrowButtonUp: true
      });
      this.slideUp();
    } else {
      this.setState({
        animateArrowButtonDown: true
      });
      this.slideDown();
    }

    this.sliderAudioRef.current.play();
  };

  slideUp = () => {
    let newSlide = this.state.selectedPadId + 1;

    if (newSlide >= pads.length) {
      newSlide = 0;
    }

    this.setState({
      selectedPadId: newSlide
    });
    this.props.handleChange(newSlide);
  };

  slideDown = () => {
    let newSlide = this.state.selectedPadId - 1;

    if (newSlide < 0) {
      newSlide = pads.length - 1;
    }

    this.setState({
      selectedPadId: newSlide
    });
    this.props.handleChange(newSlide);
  };

  getPreviousSlide = () => {
    return this.state.selectedPadId >= 1 ? this.state.selectedPadId - 1 : this.state.selectedPadId + pads.length - 1;
  }

  getNextSlide = () => {
    return this.state.selectedPadId < pads.length - 1 ? this.state.selectedPadId + 1 : this.state.selectedPadId + 1 - pads.length;
  }

  getClasses = (index) => {
    const slickAnimationClass = {
      current: ' carousel-item--current ',
      active: ' carousel-item--active ',
      prev: ' carousel-item--prev ',
      next: ' carousel-item--next '
    }
    const isCurrentSlide = this.state.selectedPadId === index;
    
    if(isCurrentSlide) {
      return slickAnimationClass.active + slickAnimationClass.current;
    }

    const isPrev = index === this.getPreviousSlide();
    const isNext = index === this.getNextSlide();
    
    if(isPrev) {
      return slickAnimationClass.prev;
    }

    if(isNext) {
      return slickAnimationClass.next;
    }

    return '';
  }

  render() {
    const { selectedPadId } = this.state;

    return (
      <div className={`pad-carousel carousel ${this.props.className} ${this.state.isSlideAnimated ? ' pad-carousel--used carousel--used ' : ''}`}>
        <AudioPlayer ref={this.sliderAudioRef} file={arrowClickSound} loop={false} />
        <div className="pad-name">
          <img className="pad-name__bcg" src={padBcg} type="" aria-label="pad" />
          <p className="pad-name__text">{pads[selectedPadId].name}</p>
        </div>
        <div className="carousel-container">
          <ArrowButton
            passedClasses={`btn btn-arrow btn-arrow--up ${
              this.state.animateArrowButtonUp ? 'btn--transform' : ''
              }`}
            handleClickButton={() => this.handleClickButton('up')}
            animationEnd={() => this.setState({ animateArrowButtonUp: false })}
          />
          {
            pads.map((pad, i) => (
              <PadSliderSel
                classes={this.getClasses(i)}
                pad={pad}
                key={'pad' + i}
                index={i}
                {...this.props}
              />
            ))
          }
          <ArrowButton
            isBottom
            passedClasses={`btn btn-arrow btn-arrow--down ${
              this.state.animateArrowButtonDown ? 'btn--transform' : ''
              }`}
            handleClickButton={() => this.handleClickButton('down')}
            animationEnd={() => this.setState({ animateArrowButtonDown: false })}
          />
        </div>
        <div className="pad-loaders">
          <div className='pad-loader'>
            <span className="pad-loader__label">UX</span>
            <PadSliderUiLoaderSvg
              padName={pads[selectedPadId].name}
              loader="ux-loader"
              loaderValue={pads[selectedPadId].uxVal}
            />
          </div>
          <div className='pad-loader'>
            <span className="pad-loader__label">UI</span>
            <PadSliderUiLoaderSvg
              padName={pads[selectedPadId].name}
              loader="ui-loader"
              loaderValue={pads[selectedPadId].uiVal}
            />
          </div>
        </div>
        <img alt="joypad" src={joypad} className="player-box" type="" />
      </div>
    );
  }
}

export default PadSlider;
