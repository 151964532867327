import styled from 'styled-components';

const Button = styled.button`
  position: absolute;
  z-index: 2;
  border: 0;
  outline: 0;
`;

export const ButtonArrow = styled(Button)`
  cursor: pointer;
  width: ${({ buttonSize, vmin, vminUnits }) => `${buttonSize.w * vmin}${vminUnits}`};
  height: ${({ buttonSize, vmin, vminUnits }) => `${buttonSize.h * vmin}${vminUnits}`};
  top: ${({ buttonPosition, vmin, vminUnits }) => `${buttonPosition.top * vmin}${vminUnits}`};
  left: ${({ buttonPosition, vmin, vminUnits }) => `${buttonPosition.left * vmin}${vminUnits}`};
  `;
  
  export const ButtonRotate = styled(Button)`
  cursor: pointer;
  width: ${({ buttonSize, vmin, vminUnits }) => `${buttonSize.w * vmin}${vminUnits}`};
  height: ${({ buttonSize, vmin, vminUnits }) => `${buttonSize.h * vmin}${vminUnits}`};
  top: ${({ buttonPosition, vmin, vminUnits }) => `${buttonPosition.top * vmin}${vminUnits}`};
  right: ${({ buttonPosition, vmin, vminUnits }) => `${buttonPosition.right * vmin}${vminUnits}`};
`;
