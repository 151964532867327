import React from "react";

import spinnerDot from "../../assets/svg/dot-loader1.svg"
import spinnerActiveDot from "../../assets/svg/dot-loader-red.svg"

import './style.scss';

const spinnerGroupsAmount = 4

function Spinner(props) {

    return (
        <>
        <div className="spinner">
            {
                [...Array(spinnerGroupsAmount)].map((dot, i) => (
                    i === 0 ?
                    <div key={'spin' + i} className="spinner__dot-group">
                        <img src={spinnerDot}  className="spinner__dot" type="" alt="dot"></img>
                        <img src={spinnerActiveDot}  className="spinner__dot" type="" alt="active dot"></img>
                    </div>
                    :
                    <div key={'spin' + i} className="spinner__dot-group">
                        <img src={spinnerDot} className="spinner__dot" type="" alt="dot"></img>
                        <img src={spinnerDot} className="spinner__dot" type="" alt="dot"></img>
                    </div>
                ))
            }
        </div>
        </>
    );
}

export default Spinner;
