import React, { Component } from "react";

import { equal2d } from '../../helpers/arrays';

import BlockCell from "./BlockCell";

class BlocksContainer extends Component {
    state = {
        blocks: []
    }

    constructor(props) {
        super(props);

        this.state.blocks = props.blocks;
    }

    componentDidUpdate(prevProps) {
        let filteredBlocks = [];

        if(!equal2d(prevProps.blocks, this.props.blocks)) {
            this.setState({blocks: this.props.blocks});
        }

        this.props.blocks.forEach((row, index) => {
            if(row.every(col => col))
                {                
                    filteredBlocks.push(index);
                }
        });

        if(filteredBlocks.length > 0 && this.props.handleFullRow && equal2d(prevProps.blocks, this.props.blocks)) {
            this.props.handleFullRow(filteredBlocks);
        }
    }
    
    render() {
        const { blocks } = this.state;

        return (
            <div className="block-rage-blocks">
                {this.props.children}
                {
                    blocks.map((row, yIndex) => (
                        <div key={`tbs-row-${yIndex}`} className="block-rage-blocks-row">
                        {                            
                            row.map((col, xIndex) => (
                                col ? 
                                <span key={`tbs-cell-${xIndex}`} className="block-rage-blocks-col">
                                    <BlockCell fillColor={col}></BlockCell>
                                </span>
                                :
                                <span key={`tbs-cell-${xIndex}`} className="block-rage-blocks-col block-rage-blocks-col--empty"></span>
                            ))
                        }
                        </div>   
                    )) 
                }
            </div>
        )
    }
}

export default BlocksContainer;