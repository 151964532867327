import React from 'react';

import mud from '../../assets/svg/mud.svg';

import { Parallax } from '..';
import { AppConsumer } from '../../AppContext';

import constants from '../../helpers/constants';
import steps from '../../helpers/steps';

const { START_VIEW, USER_FETCHING_VIEW } = steps;

const { viewSteps } = constants;

const BackgroundObjects = () => {
  return (
    <AppConsumer>
      {props => (
        <>
          <Parallax
            width={props.width}
            currentStep={props.currentStep}
            className="parallax-full"
            position="1"
          >
            <div className="ground" />
          </Parallax>
          <Parallax
            width={props.width}
            destination=""
            currentStep={props.currentStep}
            className={`parallax-full`}
            position="0"
          >
            <img alt="shrub" className="shrub0-img" src={mud} />
            <img alt="shrub" className="shrub4" src={mud} />
          </Parallax>
          {(props.currentStep > viewSteps.indexOf(START_VIEW) &&
            props.currentStep < viewSteps.indexOf(USER_FETCHING_VIEW)) && (
            <button aria-label="Slide view" className="arrow-back" onClick={props.goToPrevStep}></button>
          )}
        </>
      )}
    </AppConsumer>
  );
};

export default BackgroundObjects;
