import React, { useRef, useImperativeHandle, forwardRef } from 'react';

import { SoundConsumer } from '../../SoundContext';

import './style.scss';

const AudioContainer = ({ file, loop, autoPlay, reference }) => {
  const audioRef = useRef(null);

  useImperativeHandle(reference, () => ({
    play() {
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
      }
    },
    stop() {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    },
  }));

  const handleLoad = () => {
    if (audioRef && audioRef.current && audioRef.current.play && autoPlay) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  };

  return (
    <SoundConsumer>
      {props =>
        props.isMusicOn &&
        props.isGameVisible && (
          <audio onLoadedData={handleLoad} ref={audioRef} loop={loop}>
            <source src={file} />
            Your browser does not support the audio element.
          </audio>
        )
      }
    </SoundConsumer>
  );
};

const AudioPlayer = forwardRef(({ autoPlay, file, loop }, ref) => {
  return <AudioContainer autoPlay={autoPlay} file={file} loop={loop} reference={ref} />;
});

export default AudioPlayer;
