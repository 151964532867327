import React from 'react';
import styled, { keyframes } from 'styled-components';

const blink = keyframes`
 to {
    visibility: hidden;
}
`;

const Square = styled.div`
  display: inline-block;
  height: 30px;
  width: 15px;
  background-color: white;
  animation: ${blink} 1s steps(5, start) infinite;
`;

const InputSquare = () => {
  return <Square />;
};

export default InputSquare;
