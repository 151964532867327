import React from 'react';

import soundOn from '../../assets/img/mainView/sound.svg';
import soundOff from '../../assets/img/mainView/no-sound.svg';
import background from '../../assets/svg/sound-toggle-background.svg';

import { SoundConsumer } from '../../SoundContext';

const AudioToggle = ({ showInMenu, showBackground }) => {
  
  return (
    <SoundConsumer>
      {
        props => {
          const showAudioToggle = props.isGameVisible && ((showInMenu && props.isMenu) || (!showInMenu && !props.isMenu));

          return (
            <div className={`audio-toggle-wrapper ${showAudioToggle ? 'show' : 'hide'}`} >
              {
                showBackground &&
                <img alt="Sound toggle background" className="audio-toggle-background" src={background} />
              }
              <button aria-label="Toggle music" onClick={() => props.toggleMusic()} className={`audio-toggle ${showBackground ? 'audio-toggle-with-background' : ''}`}>
                <img alt={props.isMusicOn ? "Sound on" : "Sound off"} src={props.isMusicOn ? soundOn : soundOff} />
              </button>
            </div>
          )
        } 
      }
    </SoundConsumer>
  )
};

export default AudioToggle;
