import React from 'react';

import { avatars, avatarNames } from '../../helpers/mainData';
import blackLines from '../../assets/svg/blackLines.svg';
import cloud from '../../assets/svg/say-white.svg';

const EnemyResponse = props => {
  const { message, enemy } = props;
  const enemyAvatarId = enemy ? avatarNames.indexOf(enemy.avatar) : -1;

  return (
    <div className="enemy-response">
      <img
        className="enemy-response__user-avatar"
        src={enemyAvatarId >= 0 ? avatars[enemyAvatarId] : null}
        type=""
        aria-label="enemy avatar"
        alt="enemy response"
      ></img>
      <img
        className="enemy-response__message-bcg"
        src={cloud}
        type=""
        aria-label="text cloud"
        alt="enemy response"
      ></img>
      <div className="enemy-response__message">{message}</div>
      <img
        className="enemy-response__shadow"
        src={blackLines}
        type=""
        aria-label="black lines"
        alt="enemy response"
      ></img>
    </div>
  );
};

export default EnemyResponse;
