import React, { Component } from 'react';

import BlockCell from './BlockCell';
const gameCellWidth = 40;
const paddingX = 4;
const paddingY = 4;
const gameCellDimension = 'px';

class Block extends Component {
  render() {
    const { fillColor, position, positionsArray, gamePanelDimensions } = this.props;

    return (
      <div
        className="block-rage-block"
        style={{
          transform: `translate(${position.x * gameCellWidth + position.x * paddingX}${gameCellDimension}, 
            ${(gamePanelDimensions.y - position.y) * gameCellWidth +
            (gamePanelDimensions.y - position.y) * paddingY}${gameCellDimension})`,
        }}
      >
        {positionsArray.map((row, yIndex) => (
          <div key={`tb-row-${yIndex}`} className="block-rage-block-row">
            {row.map((col, xIndex) =>
              col === 1 ? (
                <span key={`tb-cell-${xIndex}`} className="block-rage-block-col">
                  <BlockCell fillColor={fillColor}></BlockCell>
                </span>
              ) : (
                <span
                  key={`tb-cell-${xIndex}`}
                  className="block-rage-blocks-col block-rage-blocks-col--empty"
                ></span>
              ),
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default Block;
