import React from 'react';
import constants from '../../helpers/constants';

const initialState = {
  currentLayerOffset: 0,
  parallaxClass: '',
  currentStep: 0,
  transformX: 0,
  position: 0,
  duration: '0s',
  delay: '0.5s',
  offset: 0,
};

const {
  viewsWidths,
  screenWidth,
  layerOffset,
  viewsOffsets,
  viewDimension,
  viewsTransitionTimes,
} = constants;

class Parallax extends React.Component {
  state = { ...initialState };

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      position: props.position,
      delay: props.delay ? props.delay : initialState.delay,
      currentStep: props.currentStep,
      parallaxClass: `parallax parallax-${props.position}`,
    };

    this.state.currentLayerOffset = layerOffset * (props.position - 1);
  }

  componentDidMount() {
    this.moveTo(0);

    this.updateOffset();

    window.addEventListener('resize', () => this.updateOffset());
  }

  componentDidUpdate(prevProps) {
    const { currentStep } = prevProps;

    if (currentStep !== this.props.currentStep) {
      this.moveTo(this.props.currentStep);
    }
  }

  updateOffset = () => {
    if(document.getElementsByClassName('App')[0]) {
      this.setState({
        offset: document.getElementsByClassName('App')[0].offsetWidth - screenWidth,
      });
    }
  }

  getViewOffset = step => {
    let sum = 0;

    if (step > 0 && viewsWidths[step] === 0 && viewsOffsets[step] === 0) {
      sum = this.state.transformX * -1;
    } else {
      for (let i = 0; i <= step; i++) {
        sum += (viewsWidths[i] / 100) * screenWidth + (viewsOffsets[i] / 100) * screenWidth;
      }

      sum += this.state.currentLayerOffset * step;
    }

    return sum;
  };

  moveTo(step) {
    const prevStep = this.state.currentStep;
    let duration = viewsTransitionTimes[prevStep];

    if(prevStep > step) {
      duration = viewsTransitionTimes[prevStep - 1];
    }

    this.setState({
      transformX: this.getViewOffset(step) * -1,
      duration,
      currentStep: step,
    });
  }

  render() {
    return (
      <div
        className={`parallax ${this.props.className ? this.props.className : ''} ${
          this.state.parallaxClass
        }`}
        style={{
          transform: `translateX(${this.state.transformX}${viewDimension})`,
          width: `${this.props.width}px`,
          transition: `transform ${this.state.duration} ease-out ${this.state.delay}`,
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Parallax;
