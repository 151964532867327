import React from 'react';
import styled from 'styled-components';

const CURSOR_TYPES = {
  HAND: 'hand',
  CLOCK: 'clock',
  ON_SCREEN: 'screen',
  OK: 'ok',
  DEFAULT: 'default'
}

const CursorWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  transition: left 3s ease-in-out;
  animation: ${({ animation }) => animation};
  z-index: ${({isOverLayers}) => isOverLayers ? 1001 : 3};
  opacity: 1;
`;

const CursorImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  z-index: 3;
  opacity: 1;
`;

const getCursorImage = (isGameView, mouseOnGameBox, gameLoadingStarted, isAppLoading, isGameLoading) => {
  let cursorType = CURSOR_TYPES.HAND;
  
  if(gameLoadingStarted) {
    cursorType = CURSOR_TYPES.OK;
  }

  if(isAppLoading || isGameLoading) {
    cursorType = CURSOR_TYPES.CLOCK;
  }
  
  if(isGameView) {
    cursorType = CURSOR_TYPES.DEFAULT;
  }
  
  if(mouseOnGameBox) {
    cursorType = CURSOR_TYPES.ON_SCREEN;
  }

  switch(cursorType) {
    case CURSOR_TYPES.HAND: {
      return <CursorImage
              src={require('../../assets/img/mainView/hand.svg')}
              alt=""
            />
    }
    case CURSOR_TYPES.CLOCK: {
      return <CursorImage
              src={require('../../assets/img/mainView/clock.svg')}
              alt=""
              style={{
                left: 20
              }}
            />
    }
    case CURSOR_TYPES.OK: {
      return <>
              <CursorImage
                src={require('../../assets/img/mainView/ok.svg')}
                alt=""
              />
              <CursorImage
                src={require('../../assets/img/mainView/celebrate.svg')}
                alt=""
                style={{
                  transformOrigin: 'center',
                  animation: '0.3s ease fadeAndScaleSparks 0.2s forwards'
                }}
              />
            </> 
    }
    case CURSOR_TYPES.ON_SCREEN: {
      return <CursorImage
              src={require('../../assets/img/mainView/cursor-on-screen.svg')}
              alt=""
              style={{
                width: '34px'
              }}
            />
    }
    default: {
      return <CursorImage
              src={require('../../assets/img/mainView/cursor.svg')}
              alt=""
              style={{
                width: '34px'
              }}
            />
    }
  }
}

const CustomCursor = ({ animation, isOverLayers, isGameView, roomLeft, cursorPos, mouseOnGameBox, gameLoadingStarted, isAppLoading, isGameLoading, initialRoomLeft, initialRoomTop }) => {
  return <CursorWrapper
          animation={animation}
          isOverLayers={isOverLayers}
          style={{
            left: `${(roomLeft + initialRoomLeft) * (-1)}px`,
            top: `${initialRoomTop * (-1)}px`,
            transform: `translateX(${cursorPos.x + 2}px) translateY(${cursorPos.y + 2}px)`
          }}>
          {
            getCursorImage(isGameView, mouseOnGameBox, gameLoadingStarted, isAppLoading, isGameLoading)
          }
        </CursorWrapper> 
};

export default CustomCursor;
