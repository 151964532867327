import React, { useState, useEffect } from 'react';

const loaderWidth = 121;
const loaderHeight = 15;
const dotWidthHeight = 3;
const border = 4.5;
const dotsAmount = loaderHeight / dotWidthHeight;

const PadSliderUiLoaderSvg = props => {
  const [loaderValue, setLoaderValue] = useState('0');

  if (loaderValue > loaderWidth) {
    setLoaderValue(loaderWidth);
    throw console.error('invalid value provided');
  }

  useEffect(() => {
    setLoaderValue(props.loaderValue);
  }, [props.loaderValue]);

  return (
    <>
      <svg
        // TODO use props.padName to refactor _pad-slider.scss-----------------
        className={`loader ${props.loader} ${props.padName}`}
        width={loaderWidth + 2 * border}
        height={loaderHeight + 2 * border}
        viewBox={`0 0 ${loaderWidth + 2 * border} ${loaderHeight + 2 * border}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>UX/UI loader</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="iPhone-XS-Copy-41" transform="translate(-101.000000, -490.000000)">
            <g id="Group-13" transform="translate(57.000000, 486.000000)">
              <g id="UX/UI-loader" transform="translate(44.000000, 4.000000)">
                <polygon fill="#A362B6" points="0 0 130 0 130 24 0 24" />

                <polygon fill="#281745" points="2 2 128 2 128 22 2 22" />

                <rect
                  fill="#FD0F23"
                  x={border}
                  y={border}
                  width={loaderWidth}
                  height={loaderHeight}
                />
                <rect
                  className="loader--yellow"
                  fill="#EED777"
                  width={loaderValue}
                  x={border}
                  y={border}
                  height={loaderHeight}
                />

                <g
                  className="loader__dots"
                  transform={`translate(${loaderValue - loaderHeight}, 0)`}
                >
                  {[...Array(dotsAmount)].map((el, i) =>
                    [...Array(dotsAmount)].map(
                      (subEl, j) =>
                        (i + j) % 2 === 0 && (
                          <rect
                            key={'' + i + j}
                            width="3"
                            height="3"
                            x={border + dotWidthHeight * i}
                            y={border + dotWidthHeight * j}
                            fill="#FD0F23"
                          />
                        ),
                    ),
                  )}
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default PadSliderUiLoaderSvg;
