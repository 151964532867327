import React, { useEffect, useState, useRef } from 'react';

import {
  MOVE_LEFT,
  MOVE_RIGHT,
  TRIGGER_GO_BACK,
  MOVE_DOWN,
  MOVE_TOP,
  ROTATE_LEFT,
  ROTATE_RIGHT,
  PAD_PAGE_OPENED,
} from '../../helpers/events';
import './style.scss';

import { ButtonArrow, ButtonRotate } from './GamePadButtons';

const GamePadWrapper = props => {
  const padRef = useRef();
  const [realWidth, setRealWidth] = useState(0);
  const [padButtonsPositions, setPadButtonPositions] = useState(null);
  const { socket, nickname, verticalOrientation, pad, randomHash, vmin, vminUnits } = props;

  useEffect(() => {
    socket && socket.emit(PAD_PAGE_OPENED, nickname, randomHash);
    window.addEventListener('resize', setOriginalSize);
  }, [nickname, socket, randomHash]);

  useEffect(() => {
    pad && setPadButtonPositions(pad.buttonsPositions);
  }, [pad]);

  useEffect(() => {
    window.removeEventListener('resize', setOriginalSize);
  }, []);

  const setOriginalSize = () => {
    setRealWidth(padRef.current.clientWidth);
  }

  return (
    <div className="game-pad" ref={padRef} onLoad={setOriginalSize}>
      {
        props.showArrow &&
        <button aria-label="Slide view" className="arrow-back pad-arrow-back" onClick={() => socket.emit(TRIGGER_GO_BACK, nickname, randomHash)}></button>
      }
      {props.children}
      {padButtonsPositions && (
        <div style={{height: `${vmin * 100}${vminUnits}`}} className="game-pad__buttons">
          <ButtonArrow
            vmin={vmin}
            vminUnits={vminUnits}
            realWidth={realWidth}
            vertical={verticalOrientation}
            onClick={() => socket.emit(MOVE_TOP, nickname, randomHash)}
            buttonPosition={padButtonsPositions.top}
            buttonSize={padButtonsPositions.arrowButtons}
          />
          <ButtonArrow
            vmin={vmin}
            vminUnits={vminUnits}
            realWidth={realWidth}
            vertical={verticalOrientation}
            onClick={() => socket.emit(MOVE_DOWN, nickname, randomHash)}
            buttonPosition={padButtonsPositions.bottom}
            buttonSize={padButtonsPositions.arrowButtons}
          />
          <ButtonArrow
            vmin={vmin}
            vminUnits={vminUnits}
            realWidth={realWidth}
            vertical={verticalOrientation}
            onClick={() => socket.emit(MOVE_LEFT, nickname, randomHash)}
            buttonPosition={padButtonsPositions.left}
            buttonSize={padButtonsPositions.arrowButtons}
          />
          <ButtonArrow
            vmin={vmin}
            vminUnits={vminUnits}
            realWidth={realWidth}
            vertical={verticalOrientation}
            onClick={() => socket.emit(MOVE_RIGHT, nickname, randomHash)}
            buttonPosition={padButtonsPositions.right}
            buttonSize={padButtonsPositions.arrowButtons}
          />
          <ButtonRotate
            vmin={vmin}
            vminUnits={vminUnits}
            realWidth={realWidth}
            vertical={verticalOrientation}
            onClick={() => socket.emit(ROTATE_RIGHT, nickname, randomHash)}
            buttonPosition={padButtonsPositions.rotateRight}
            buttonSize={padButtonsPositions.rotateButtons}
          />
          {padButtonsPositions.rotateLeft && (
            <ButtonRotate
              vmin={vmin}
              vminUnits={vminUnits}
              realWidth={realWidth}
              vertical={verticalOrientation}
              onClick={() => socket.emit(ROTATE_LEFT, nickname, randomHash)}
              buttonPosition={padButtonsPositions.rotateLeft}
              buttonSize={padButtonsPositions.rotateButtons}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default GamePadWrapper;
