const events = {
  PAUSE_USER_FETCHING: 'PAUSE_USER_FETCHING',
  RESTART_USER_FETCHING: 'RESTART_USER_FETCHING',
  GO_TO_START: 'GO_TO_START',
  RESET_USER_DATA: 'RESET_USER_DATA',
  STOP_USER_FETCHING: 'STOP_USER_FETCHING',
  NEW_PAD_DETECTED: 'NEW_PAD_DETECTED',
  REMOVE_PAD_ARROW: 'REMOVE_PAD_ARROW',
  ADD_PAD_ARROW: 'ADD_PAD_ARROW',
  NEW_PAD_MATCHED: 'NEW_PAD_MATCHED',
  CURRENT_SCORES: 'CURRENT_SCORES',
  TEMP_ENEMY_SCORES: 'TEMP_ENEMY_SCORES',
  USER_VERIFIED: 'USER_VERIFIED',
  USER_TRAINING_VERIFIED: 'USER_TRAINING_VERIFIED',
  USER_CONNECTED: 'USER_CONNECTED',
  USER_TRAINING_READY: 'USER_TRAINING_READY',
  USER_READY: 'USER_READY',
  START_GAME: 'START_GAME',
  ENEMY_DISCONNECTED: 'ENEMY_DISCONNECTED',
  CREATE_PAIR: 'CREATE_PAIR',
  CREATED_PAIR: 'CREATED_PAIR',
  CREATE_PAIR_FAILED: 'CREATE_PAIR_FAILED',
  CHANGES_RECEIVED: 'CHANGES_RECEIVED',
  CHANGES_SEND: 'CHANGES_SEND',
  VALID_USERNAME: 'VALID_USERNAME',
  INVALID_USERNAME: 'INVALID_USERNAME',
  USER_LOST: 'USER_LOST',
  GAME_OVER: 'GAME_OVER',
  ENEMY_MESSAGE: 'ENEMY_MESSAGE',
  MY_MESSAGE: 'MY_MESSAGE',
  MOVE_LEFT: 'MOVE_LEFT',
  MOVE_RIGHT: 'MOVE_RIGHT',
  MOVE_DOWN: 'MOVE_DOWN',
  MOVE_TOP: 'MOVE_TOP',
  GO_BACK: 'GO_BACK',
  TRIGGER_GO_BACK: 'TRIGGER_GO_BACK',
  ROTATE_RIGHT: 'ROTATE_RIGHT',
  ROTATE_LEFT: 'ROTATE_LEFT',
  PAD_PAGE_OPENED: 'PAD_PAGE_OPENED',
  SET_RANDOM_HASH: 'SET_RANDOM_HASH',
  PAD_MATCHED: 'PAD_MATCHED',
  GAME_READY: 'GAME_READY',
  GAME_START: 'GAME_START',
  SOCKET_IO_CONNECTION: 'SOCKET_IO_CONNECTION'
};

module.exports = events;
