import React, { Component } from 'react';
import AvatarSliderSel from '../../components/AvatarSlider/AvatarSliderSel';
import ArrowButton from '../Buttons/ArrowButton/ArrowButton';
import { avatars } from '../../helpers/mainData';
import player from '../../assets/svg/player-full.svg';
import arrowClickSound from '../../assets/sounds/strzaleczki-wybor-padow-postaci-klik.wav';

import './style.css';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

const events = require('../../helpers/events.js');
const {
  MOVE_DOWN,
  MOVE_TOP
} = events;

export default class AvatarSlider extends Component {
  initialAvatarId = 1;
  slidesToShow = 3;
  sliderAudioRef;
  
  constructor(props) {
    super(props);
    this.sliderAudioRef = React.createRef();
    this.state = {
      avatars: [...avatars],
      selectedAvatarId: this.initialAvatarId,
      isSlideAnimated: false,
      arrowClicked: false,
      animateArrowButtonUp: false,
      animateArrowButtonDown: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isSlideAnimated: true });
    }, 2000);
  }

  componentDidUpdate(prevProps) {
    this.checkPadMoveClicks(prevProps.clickedDirection);
  }

  checkPadMoveClicks = (prevClickDirection) => {
    if(this.props.clickedDirection !== prevClickDirection) {
      switch(this.props.clickedDirection) {
        case MOVE_DOWN: {
          this.handleClickButton('down');
          break;
        }
        case MOVE_TOP: {
          this.handleClickButton('up');
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  handleClickButton = buttonType => {
    this.sliderAudioRef.current.play();

    switch(buttonType)
    {
      case 'up': {
        this.setState({
          animateArrowButtonUp: true
        });
        this.slideUp();
        break;
      } 
      default: {
        this.setState({
          animateArrowButtonDown: true
        });
        this.slideDown();
        break;
      }
    }
  };

  getPreviousSlide = () => {
    return this.state.selectedAvatarId >= 2 ? this.state.selectedAvatarId - 2 : this.state.selectedAvatarId + this.state.avatars.length - 2;
  }

  getNextSlide = () => {
    return this.state.selectedAvatarId < this.state.avatars.length - 2 ? this.state.selectedAvatarId + 2 : this.state.selectedAvatarId + 2 - this.state.avatars.length;
  }

  getClasses = (index) => {
    const slickAnimationClass = {
      current: ' carousel-item--current ',
      active: ' carousel-item--active ',
      prev: ' carousel-item--prev ',
      next: ' carousel-item--next '
    }
    const isCurrentSlide = this.state.selectedAvatarId === index;
    
    if(isCurrentSlide) {
      return slickAnimationClass.active + slickAnimationClass.current;
    }

    const isFirst = this.state.selectedAvatarId === 0 ? (index === (this.state.avatars.length - 1)) : (index === (this.state.selectedAvatarId - 1));
    const isLast = this.state.selectedAvatarId === (this.state.avatars.length - 1) ? (index === 0) : (index === (this.state.selectedAvatarId + 1));

    const isPrev = index === this.getPreviousSlide();
    const isNext = index === this.getNextSlide();
    
    if(isFirst) {
      return slickAnimationClass.active + slickAnimationClass.prev;
    }

    if(isLast) {
      return slickAnimationClass.active + slickAnimationClass.next;
    }
    
    if(isPrev) {
      return slickAnimationClass.prev;
    }

    if(isNext) {
      return slickAnimationClass.next;
    }

    return '';
  }

  slideUp = () => {
    let newSlide = this.state.selectedAvatarId + 1;

    if(newSlide === avatars.length) {
      newSlide = 0;
    }

    this.setState({
      selectedAvatarId: newSlide
    });
    this.props.handleChange(newSlide);
  };

  slideDown = () => {
    let newSlide = this.state.selectedAvatarId - 1;

    if(newSlide < 0) {
      newSlide = avatars.length - 1;
    }

    this.setState({
      selectedAvatarId: newSlide
    });
    this.props.handleChange(newSlide);
  };

  render() {
    return (
      <div className={`avatar-carousel carousel ${this.props.className} ${this.state.isSlideAnimated ? ' avatar-carousel--used carousel--used ' : ''}`}>
        <AudioPlayer ref={this.sliderAudioRef} file={arrowClickSound} />
        <div className="carousel-container">
          <ArrowButton
            passedClasses={`btn btn-arrow btn-arrow--up ${
              this.state.animateArrowButtonUp ? 'btn--transform' : ''
            }`}
            handleClickButton={() => this.handleClickButton('up')}
            animationEnd={() => this.setState({ animateArrowButtonUp: false })}
          />
          {
            this.state.avatars.map((avatar, i) => (
              <AvatarSliderSel
                avatar={avatar}
                classes={this.getClasses(i)}
                {...this.props} 
                key={'avatar' + i} 
                index={i} 
              />
            ))
          }
          <ArrowButton
            isBottom
            passedClasses={`btn btn-arrow btn-arrow--down ${
              this.state.animateArrowButtonDown ? 'btn--transform' : ''
            }`}
            handleClickButton={() => this.handleClickButton('down')}
            animationEnd={() => this.setState({ animateArrowButtonDown: false })}
          />
        </div>
        <img src={player} className="player-box" alt="Player avatar" />
      </div>
    );
  }
}
