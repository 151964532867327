import React, { Component } from 'react';

import Building from '../Building/Building';
import Block from '../Block/Block';
import GamePanel from './GamePanel';
import BlocksContainer from '../Block/BlocksContainer';
import GameScores from './GameScores';

import scoresSound from '../../assets/sounds/zdobycie-punktu.wav';
import constants from '../../helpers/constants';
import { getRandomBlock, getRotatedBlock } from '../../helpers/blocks';

import './style.scss';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

const events = require('../../helpers/events.js');
const { CHANGES_SEND, MOVE_LEFT, GAME_READY, MOVE_RIGHT } = events;

const { gamePanelDimensions } = constants;
const initialSpeed = 1000;
const fastSpeed = 100;

class GameBox extends Component {
  state = {
    position: {
      x: 0,
      y: gamePanelDimensions.y - 1,
    },
    blocks: [],
    scores: 0,
    moving: false,
    fillColor: '',
    gamePaused: false,
    gameStarted: false,
    speed: initialSpeed,
    displayPositionsArray: [],
    originalPositionsArray: [],
    availableDimensions: { ...gamePanelDimensions },
    enemyAvailableDimensions: { ...gamePanelDimensions },
    socket: null,
  };

  constructor(props) {
    super(props);
    let bl = [...Array(gamePanelDimensions.y)];
    bl = bl.map(() => [...Array(gamePanelDimensions.x)]); // array YxX
    let randomBlock = getRandomBlock();

    this.scoresSoundRef = React.createRef();
    this.state.position = { ...this.getRandomBlockPosition(randomBlock.displayBlock) };
    this.state.displayPositionsArray = randomBlock.displayBlock;
    this.state.originalPositionsArray = randomBlock.block;
    this.state.fillColor = randomBlock.color;
    this.state.blocks = [...bl];
  }

  componentDidMount() {
    this.sendGameReady();

    if(this.props.gameReady) {
      this.startGame();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.gameReady && this.props.gameReady) {
      this.startGame();
    }

    if (
      !this.state.gamePaused &&
      prevProps.availableDimensions.y !== this.props.availableDimensions.y
    ) {
      this.setState({ availableDimensions: { ...this.props.availableDimensions } });
      this.checkRanges(this.props.availableDimensions);
    }

    if (this.props.moveBlock && prevProps.moveBlock !== this.props.moveBlock) {
      switch (this.props.moveBlock) {
        case MOVE_LEFT: {
          this.moveToLeft();
          break;
        }
        case MOVE_RIGHT: {
          this.moveToRight();
          break;
        }
        default: {
          break;
        }
      }
    }

    if (this.props.rotateBlock && prevProps.rotateBlock !== this.props.rotateBlock) {
      this.rotateBlock(this.props.rotateBlock);
    }

    if (this.props.fallFaster && prevProps.fallFaster !== this.props.fallFaster) {
      this.setState({
        speed: fastSpeed,
      });
    }
  }

  startGame = () => {
    if(!this.state.gameStarted) {
      setTimeout(() => {
        if(!this.state.gameStarted) {
          this.setState({ gamePaused: false, gameStarted: true });
          this.fall();
        }
      }, 5000);
    }
  }

  sendGameReady = () => {
    if(!this.props.isTraining) {
      this.props.socket.emit(GAME_READY)
    } else {
      this.startGame();
    }
  }

  setRandomBlock = () => {
    let randomBlock = getRandomBlock();

    this.setState({
      fillColor: randomBlock.color,
      displayPositionsArray: [...randomBlock.displayBlock],
      originalPositionsArray: [...randomBlock.block],
      position: { ...this.getRandomBlockPosition(randomBlock.displayBlock) },
    });
  };

  getRandomBlockPosition = displayPositionsArray => {
    return {
      x: Math.floor(
        Math.random() * (this.state.availableDimensions.x - displayPositionsArray[0].length),
      ),
      y: this.state.availableDimensions.y,
    };
  };

  rotateBlock = side => {
    const newBlock = getRotatedBlock(this.state.originalPositionsArray, side);

    for (let i = 0; i < 4; i++) {
      if (
        !this.hasConflict(this.state.position.x - i, this.state.position.y, newBlock.displayBlock)
      ) {
        this.setState({
          position: {
            x: this.state.position.x - i,
            y: this.state.position.y,
          },
          originalPositionsArray: newBlock.block,
          displayPositionsArray: newBlock.displayBlock,
        });
        break;
      }
    }
  };

  moveToLeft = () => {
    const { position } = this.state;

    if (position.x > 0 && !this.hasConflict(position.x - 1, position.y)) {
      this.moveTo(position.x - 1, position.y);
      this.setState({ moving: true });
      setTimeout(() => {
        this.setState({ moving: false });
      }, 500);
    }
  };

  moveToRight = () => {
    const { displayPositionsArray, position } = this.state;

    if (
      position.x + displayPositionsArray[0].length < gamePanelDimensions.x &&
      !this.hasConflict(position.x + 1, position.y)
    ) {
      this.moveTo(position.x + 1, position.y);
      this.setState({ moving: true });
      setTimeout(() => {
        this.setState({ moving: false });
      }, 500);
    }
  };

  hasConflict = (x, y, displayPositionsArray = this.state.displayPositionsArray) => {
    const { blocks, availableDimensions } = this.state;

    if (y < displayPositionsArray.length) {
      return true;
    }

    if (x > availableDimensions.x - displayPositionsArray[0].length || x < 0) {
      return true;
    }

    for (let i = 0; i < displayPositionsArray.length; i++) {
      for (let j = 0; j < displayPositionsArray[i].length; j++) {
        if (displayPositionsArray[i][j] && blocks[gamePanelDimensions.y - (y - i)][j + x]) {
          return true;
        }
      }
    }

    return false;
  };

  addBlock = (x, y) => {
    const { displayPositionsArray, blocks, fillColor } = this.state;
    let tmpBlocks = [...blocks];

    for (let i = 0; i < displayPositionsArray.length; i++) {
      for (let j = 0; j < displayPositionsArray[0].length; j++) {
        if (displayPositionsArray[i][j]) {
          tmpBlocks[gamePanelDimensions.y - (y - i)][j + x] = fillColor;
        }
      }
    }

    this.setState({
      speed: initialSpeed,
      blocks: [...tmpBlocks],
    });
    this.setRandomBlock();

    if (this.hasConflict(this.state.position.x, this.state.position.y) && !this.state.moving) {
      this.setGameOver();
      return;
    }

    this.fall();
  };

  checkRanges = (availableDimensions) => {
    const { blocks } = this.state;
    let isValid = true;

    for (let i = 0; i > gamePanelDimensions.y - availableDimensions.y; i++) {
      isValid = isValid && !blocks[i].some(cell => cell);

      if (!isValid) {
        break;
      }
    }

    if (!isValid) {
      this.setGameOver();
    }
  };

  moveTo = (x, y) => {
    if (!this.state.gamePaused) {
      this.setState({ position: { x, y } });
    }
  };

  fall = ignore => {
    const { x, y } = this.state.position;

    if (this.state.gamePaused) {
      return;
    }

    if (this.hasConflict(x, y - 1)) {
      this.moveTo(x, y);
      if (!this.state.moving || ignore) {
        setTimeout(() => {
          this.addBlock(x, y);
        });
      } else {
        setTimeout(() => {
          this.fall(true);
        }, 500);
      }
    } else {
      this.moveTo(x, y - 1);
      setTimeout(this.fall, this.state.speed);
    }
  };

  setGameOver = () => {
    if (this.props.handleGameOver) {
      this.props.handleGameOver(this.state.scores);
    }
  };

  handleFullRow = rows => {
    const padRowsIndex = ((this.props.pad % 2 === 0) ? 2 : 3);
    let tmlBlocks = [...this.state.blocks];
    const rowsToRemove = Math.floor(rows.length / padRowsIndex);
    const leftRows = this.state.enemyAvailableDimensions.y - rowsToRemove < 0
      ? this.state.enemyAvailableDimensions.y
      : this.state.enemyAvailableDimensions.y - rowsToRemove;
    
    const enemyAvailableDimensions =
      {
        ...this.state.enemyAvailableDimensions,
        y: leftRows,
      };

    this.scoresSoundRef.current.play();
    this.props.handleScoresChange(this.state.scores + 2 * rows.length - 1);
    this.setState({
      scores: this.state.scores + 2 * rows.length - 1
    });

    rows.reverse().forEach(element => {
      tmlBlocks.splice(element, 1);
    });

    rows.reverse().forEach(() => {
      tmlBlocks.unshift([...Array(gamePanelDimensions.x)]);
    });

    this.setState({
      blocks: [...tmlBlocks],
    });

    if(rowsToRemove > 0) {
      // the enemyScores are set only after the enemy has ended the game
      this.setState({ enemyAvailableDimensions });

      if(!this.props.isTraining) {
        this.props.socket.emit(CHANGES_SEND, enemyAvailableDimensions);
      } else if(this.props.sendChanges) {
        this.props.sendChanges(enemyAvailableDimensions);
      }
    }
  };

  render() {
    const {
      blocks,
      availableDimensions,
      position,
      displayPositionsArray,
      fillColor,
      scores,
    } = this.state;

    return (
      <div className="game">
        <div className="game-box">
          <Building default={true} />
          <GameScores scores={scores} />
          <AudioPlayer ref={this.scoresSoundRef} file={scoresSound} loop={false} />
          {displayPositionsArray && (
            <BlocksContainer handleFullRow={this.handleFullRow} blocks={blocks}>
              <Block
                gamePanelDimensions={gamePanelDimensions}
                position={position}
                positionsArray={displayPositionsArray}
                fillColor={fillColor}
              />
            </BlocksContainer>
          )}
          <GamePanel
            rowsChanged={this.props.rowsChanged}
            gamePanelDimensions={availableDimensions}
          />
        </div>
        <div className="game-control" />
      </div>
    );
  }
}

export default GameBox;
