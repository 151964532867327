export default {
  START_VIEW: 'START_VIEW',
  AVATAR_SELECT_VIEW: 'AVATAR_SELECT_VIEW',
  PAD_SELECT_VIEW: 'PAD_SELECT_VIEW',
  USER_FETCHING_VIEW: 'USER_FETCHING_VIEW',
  ENEMY_GREETING_VIEW: 'ENEMY_GREETING_VIEW',
  GAME_VIEW: 'GAME_VIEW',
  GAME_OVER_VIEW: 'GAME_OVER_VIEW',
  TRAINING_VIEW: 'TRAINING_VIEW',
};
