import React from 'react';

import { computeScores } from '../../helpers/blocks';
import zero from '../../assets/img/scores/0.svg';
import one from '../../assets/img/scores/1.svg';
import two from '../../assets/img/scores/2.svg';
import three from '../../assets/img/scores/3.svg';
import four from '../../assets/img/scores/4.svg';
import five from '../../assets/img/scores/5.svg';
import six from '../../assets/img/scores/6.svg';
import seven from '../../assets/img/scores/7.svg';
import eight from '../../assets/img/scores/8.svg';
import nine from '../../assets/img/scores/9.svg';

const GameScores = props => {
  const getImageSrc = (element) => {
    switch(element) {
      case 1: return one;
      case 2: return two;
      case 3: return three;
      case 4: return four;
      case 5: return five;
      case 6: return six;
      case 7: return seven;
      case 8: return eight;
      case 9: return nine;
      default: return zero;
    }
  }

  return (
    <div className="game-scores">
      {computeScores(props.scores).map((element, index) => (
        <div key={`score-${index}`} className="game-score">
          <img src={getImageSrc(element)}></img>
        </div>
      )
      )}
    </div>
  );
};

export default GameScores;
