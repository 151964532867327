import React from "react";

const LogoDashes = (props) => {
  const dashesAmount = props.dashesAmount ? props.dashesAmount : 12;
  const dashToSpace = props.dashToSpace ? props.dashToSpace : 1.5;
  const spaceBetweenY = props.spaceBetweenY ? props.spaceBetweenY : 2;
  const dashHeight = props.dashHeight ? props.dashHeight : 4;
  const logoWidth = props.logoWidth ? props.logoWidth : 265;
  const fillColor = props.fillColor ? props.fillColor : '#D8D8D8';
  const spaceBetweenX = logoWidth / ((dashesAmount - 1) + dashesAmount * dashToSpace);
  const dashWidth = spaceBetweenX * dashToSpace;

  return (
    <>
      <svg
        className="dash-array"
        widht={logoWidth}
        height={dashHeight * 2 + spaceBetweenY}
        viewBox={`0 0 ${logoWidth} ${dashHeight * 2 + spaceBetweenY}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        {/*     <style>         * { cursor: none; user-select: none; }     </style><!--Generator: Sketch 53.2 (72643) - https://sketchapp.com --> */}
        <title>Logo</title>
        <desc>Created with Sketch.</desc>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="iPhone-XS-Copy-51"
            transform="translate(-359.000000, -130.000000)"
          >
            <g id="Logo" transform="translate(359.000000, 130.000000)">
              <g
                id="Combined-Shape-2"
                strokeWidth="1"
                transform="translate(0.325733, 0.000000)"
              >
                {
                  [...Array(dashesAmount)].map((el, i) => (
                      <g key={`dash-1-${i}`}>
                        <rect
                            id={`Rectangle-${i}`}
                            fill={fillColor}
                            x={i * (spaceBetweenX + dashWidth)}
                            y={0}
                            width={dashWidth}
                            height={dashHeight}
                        />
                        <rect
                            id={`Rectangle-Copy-${i}`}
                            fill={fillColor}
                            x={i * (spaceBetweenX + dashWidth)}
                            y={dashHeight + spaceBetweenY}
                            width={dashWidth}
                            height={dashHeight}
                        />
                      </g>
                  ))
                }
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default LogoDashes;
