import React, { Suspense } from 'react';
import { disableBodyScroll } from 'body-scroll-lock';

import Controller from './components/Controller/Controller';
// import Start from './layers/Start/Start';
import AppBackground from './layers/AppBackground/AppBackground';

import constants from './helpers/constants';

import './App.css';
import { DesktopOnlyScreen } from './components';
import constantValues from './helpers/constants';
import { SoundProvider } from './SoundContext';
import { Ribbon } from './components/Ribbon/Ribbon';

const Start = React.lazy(() => import('./layers/Start/Start'));

const expectedHeight = constants.screenHeight;

class App extends React.Component {
  state = {
    isMobile: false,
    scale: 1,
    width: 375,
    isController: false,
    appMounted: false,
    mouseOnGameBox: false,
    renderGame: false,
    isMenu: false,
    isGameVisible: false,
    isMusicOn: true,
  };

  constructor(props) {
    super(props);
    this.handleTurnOnOffMusic = this.handleTurnOnOffMusic.bind(this);
    this.state.isMobile = this.getIsMobile();
  }

  componentDidMount() {
    this.setScale();

    if (window.location.pathname.includes('/controller')) {
      this.setState({ isController: true });
    }

    window.addEventListener('resize', this.setScale);

    const App = document.getElementsByTagName('html')[0];
    disableBodyScroll(App);
  }

  getIsMobile = () => {
    const isSmallWindow = window.innerWidth < 920;
    const isMobileDevice = navigator.userAgent.toLowerCase().indexOf('mobile') >= 0;

    return isSmallWindow || isMobileDevice;
  };

  setScale = () => {
    let height = window.innerHeight;
    let width = window.innerWidth;
    let scale = (0.75 * height) / expectedHeight;

    this.setState({
      scale,
      width: width / scale,
      appMounted: true,
      isMobile: this.getIsMobile(),
    });
  };

  handleTurnOnOffMusic(value = !this.state.isMusicOn) {
    this.setState({
      isMusicOn: value,
    });
  }

  handleMouseEnter = () => {
    this.setState({
      mouseOnGameBox: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      mouseOnGameBox: false,
    });
  };

  renderGameOnComputer = () => {
    this.setState({
      renderGame: true,
    });
  };

  handleActivateMenu = () => {
    this.setState({
      isMenu: true,
      isGameVisible: true,
    });
  };

  handleMenuSelect = () => {
    this.setState({
      isMenu: false,
    });
  };

  render() {
    return (
      <SoundProvider value={{ ...this.state, toggleMusic: this.handleTurnOnOffMusic }}>
        {this.state.isController ? (
          <Controller />
        ) : (
          <>
            <div className={this.state.isMobile ? '' : 'd-none'}>
              <DesktopOnlyScreen />
            </div>
            <div className={this.state.isMobile ? 'd-none App desktop' : 'App desktop'}>
              <AppBackground
                mouseOnGameBox={this.state.mouseOnGameBox}
                renderGameOnComputer={this.renderGameOnComputer}
                scale={this.state.scale}
              >
                <div
                  className="GameContainer"
                  style={{ width: `${this.state.scale * constantValues.screenWidth}px` }}
                >
                  {this.state.renderGame && (
                    <div
                      onMouseEnter={this.handleMouseEnter}
                      onMouseLeave={this.handleMouseLeave}
                    >
                      <Suspense fallback={<div></div>}>
                        <Start
                          scale={this.state.scale}
                          width={this.state.width}
                          isController={this.state.isController}
                          onGoToMenu={this.handleActivateMenu}
                          onMenuSelect={this.handleMenuSelect}
                          toggleMusic={this.handleTurnOnOffMusic}
                          />
                      </Suspense>
                    </div>
                  )}
                </div>
              </AppBackground>
            </div>
            <Ribbon></Ribbon>
          </>
        )}
      </SoundProvider>
    );
  }
}

export default App;
