import steps from './steps';

export const socketUrl = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:4200/';
export const appUrl = document.location.origin;

const screenWidth = 400;
const screenHeight = 812;
const {
  START_VIEW,
  AVATAR_SELECT_VIEW,
  PAD_SELECT_VIEW,
  USER_FETCHING_VIEW,
  ENEMY_GREETING_VIEW,
  GAME_VIEW,
  GAME_OVER_VIEW,
  TRAINING_VIEW,
} = steps;

const constantValues = {
  screenWidth: screenWidth,
  screenHeight: 920,
  viewsWidths: [0, 100, 0, 100, 0, 100, 100, 100],
  viewsOffsets: [0, 225, 10, 350, 0, 0, 55, 0],
  viewSteps: [
    START_VIEW,
    AVATAR_SELECT_VIEW,
    PAD_SELECT_VIEW,
    USER_FETCHING_VIEW,
    ENEMY_GREETING_VIEW,
    GAME_VIEW,
    GAME_OVER_VIEW,
    TRAINING_VIEW,
  ],
  viewsTransitionTimes: ['3s', '1s', '5s', '1s', '2s'],
  viewsTransitionMsTimes: [3000, 1000, 5000, 1000, 2000],
  layerOffset: 150,
  viewDimension: 'px',
  carouselSizes: {
    full: '50vh',
    half: screenHeight * 0.165 + 'px',
  },
  gamePanelDimensions: {
    x: 8,
    y: 16,
  },
};

export const tetrisBlockColors = {
  yellow: '#f1d404',
  pink: '#DB2E4D',
  blue: '#2973B1',
  purple: '#5A568C',
};

// inspired by Michal Zuk :)
const o = [
  [0, 0, 0, 0],
  [0, 1, 1, 0],
  [0, 1, 1, 0],
  [0, 0, 0, 0],
];

const i = [
  [0, 1, 0, 0],
  [0, 1, 0, 0],
  [0, 1, 0, 0],
  [0, 1, 0, 0],
];

const t = [
  [0, 0, 1, 0],
  [0, 1, 1, 0],
  [0, 0, 1, 0],
  [0, 0, 0, 0],
];

const s = [
  [0, 0, 0, 0],
  [0, 0, 1, 1],
  [0, 1, 1, 0],
  [0, 0, 0, 0],
];

const z = [
  [0, 0, 0, 0],
  [0, 1, 1, 0],
  [0, 0, 1, 1],
  [0, 0, 0, 0],
];

const j = [
  [0, 0, 1, 0],
  [0, 0, 1, 0],
  [0, 1, 1, 0],
  [0, 0, 0, 0],
];

const l = [
  [0, 1, 0, 0],
  [0, 1, 0, 0],
  [0, 1, 1, 0],
  [0, 0, 0, 0],
];

export const blocks = [o, t, i, s, z, j, l];

export default constantValues;
